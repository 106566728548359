import { Button, Card, CardBody } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlag, faSave, faUndoAlt } from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import ConfirmModal from "../../../components/modals/ConfirmModal";
import {
  approveCase,
  flagCase,
  forceCaseToManual,
  rejectCase,
  saveLabels,
  saveMeshTags,
  saveSpecialtyTags
} from "../../../actions/tagging.actions";
import { compact, find, get, isEmpty, isEqual, isNil, map } from "lodash";
import {
  isTaggingApprovalActive,
  isTaggingFlagActive,
  isTaggingForceToManualActive,
  isTaggingRejectedActive
} from "../../../utils/case-utils";
import { hasSpeciality } from "../../../utils/tagging-utils";
import Icon from "../../../components/common/icon";

export const TaggingActionButtons = (props) => {
  const {
    activeCase,
    caseLock,
    refreshCases,
    addedTerms,
    removedTerms,
    addedSpecialties,
    removedSpecialties,
    caseLabels
  } = props;
  const dispatch = useDispatch();
  const approveSaving = useSelector((state) => state.tagging.approveSaving);
  const flagSaving = useSelector((state) => state.tagging.flagSaving);
  const rejectSaving = useSelector((state) => state.tagging.rejectSaving);
  const forceSaving = useSelector((state) => state.tagging.forceSaving);
  const tagsSaving = useSelector((state) => state.tagging.tagsSaving);
  const taggingSpecialties = useSelector((state) => [
    ...state.reference.taggingSpecialties,
    ...state.reference.taggingSubSpecialties
  ]);

  const taggingLabels = useSelector((state) => state.reference.sortedLabels);
  const [internalApproveSaving, setApproveSaving] = useState(false);
  const [approveModal, setApproveModal] = useState(false);
  const [flagModal, setFlagModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [forceModal, setForceModal] = useState(false);
  const [saveModal, setSaveModal] = useState(false);

  const toggleApproveModal = () => setApproveModal(!approveModal);
  const toggleFlagModal = () => setFlagModal(!flagModal);
  const toggleRejectModal = () => setRejectModal(!rejectModal);
  const toggleForceModal = () => setForceModal(!forceModal);
  const toggleSaveModal = () => setSaveModal(!saveModal);

  const caseUuid = get(activeCase, "caseUuid");
  const caseState = get(activeCase, "caseState");
  const existingLabels = get(activeCase, "labels");
  const existingSpecialties = get(activeCase, "specialtyUuids", []);
  const specialtyFound = hasSpeciality(existingSpecialties, taggingSpecialties);

  const hasTagModifications =
    !isEmpty(addedSpecialties) ||
    !isEmpty(removedSpecialties) ||
    !isEmpty(addedTerms) ||
    !isEmpty(removedTerms) ||
    !isEqual(existingLabels, caseLabels);

  const manualButton = isTaggingForceToManualActive(caseState);

  const onApprove = async () => {
    setApproveSaving(true);

    if (hasTagModifications) {
      await saveTags();
    }

    await dispatch(approveCase(caseUuid, refreshCases));

    setApproveSaving(false);
    toggleApproveModal();
  };

  const onFlag = async () => {
    await dispatch(flagCase(caseUuid, refreshCases));

    toggleFlagModal();
  };

  const onReject = async () => {
    await dispatch(rejectCase(caseUuid, refreshCases));

    toggleRejectModal();
  };

  const onForceToManual = async () => {
    await dispatch(forceCaseToManual(caseUuid, refreshCases));

    toggleForceModal();
  };

  const saveTags = async () => {
    if (!isEmpty(addedSpecialties) || !isEmpty(removedSpecialties)) {
      await dispatch(
        saveSpecialtyTags(caseUuid, addedSpecialties, removedSpecialties)
      );
    }

    if (!isEmpty(addedTerms) || !isEmpty(removedTerms)) {
      await dispatch(saveMeshTags(caseUuid, addedTerms, removedTerms));
    }

    if (!isEqual(existingLabels, caseLabels)) {
      await dispatch(
        saveLabels(
          caseUuid,
          compact(
            map(caseLabels, (label) => {
              return get(
                find(taggingLabels, (l) => isEqual(l.key, label)),
                "uuid"
              );
            })
          )
        )
      );
    }
  };

  const onSaveTags = async () => {
    await saveTags();

    toggleSaveModal();
  };

  return (
    <Card className="my-3 action-button-card">
      <CardBody className="d-flex justify-content-center">
        {!manualButton && (
          <>
            <Button
              title={"Move case to 'Approved' for feed"}
              className="btn-round"
              color="success"
              disabled={
                !isNil(caseLock) ||
                !isTaggingApprovalActive(caseState) ||
                !specialtyFound ||
                hasTagModifications
              }
              onClick={toggleApproveModal}>
              <Icon
                icon={"verified-checkmark-t15"}
                size={"large"}
                color={"white"}
              />
            </Button>
            <Button
              title={"Move case to 'Flagged'"}
              className="btn-round mx-3"
              color="primary"
              disabled={!isNil(caseLock) || !isTaggingFlagActive(caseState)}
              onClick={toggleFlagModal}>
              <FontAwesomeIcon icon={faFlag} color="white" />
            </Button>
            <Button
              title={"Move case to 'Pending' moderation"}
              className="btn-round"
              color="info"
              disabled={!isNil(caseLock) || !isTaggingRejectedActive(caseState)}
              onClick={toggleRejectModal}>
              <FontAwesomeIcon icon={faUndoAlt} size={"lg"} color="white" />
            </Button>
            <Button
              title={"Save all tag modifications"}
              className="btn-round ml-3"
              color="info"
              disabled={!isNil(caseLock) || !hasTagModifications}
              onClick={toggleSaveModal}>
              <FontAwesomeIcon icon={faSave} size={"lg"} color="white" />
            </Button>
          </>
        )}

        {manualButton && (
          <Button color="primary" outline size="sm" onClick={toggleForceModal}>
            Move to Manual Tagging
          </Button>
        )}
      </CardBody>

      <ConfirmModal
        toggle={toggleApproveModal}
        isOpen={approveModal}
        message={`This case will be moved to the 'Approved' state.  ${
          hasTagModifications
            ? "Additionally, all tag modifications will be saved first."
            : ""
        } Proceed?`}
        onConfirm={onApprove}
        saving={internalApproveSaving || approveSaving}
      />
      <ConfirmModal
        toggle={toggleFlagModal}
        isOpen={flagModal}
        message="This case will be moved to the 'Tagging Flagged' state. Proceed?"
        onConfirm={onFlag}
        saving={flagSaving}
      />
      <ConfirmModal
        toggle={toggleRejectModal}
        isOpen={rejectModal}
        message="This case will be moved to back to moderation. Proceed?"
        onConfirm={onReject}
        saving={rejectSaving}
      />
      <ConfirmModal
        toggle={toggleForceModal}
        isOpen={forceModal}
        message="This case will skip auto tagging and move to 'Untagged'. Proceed?"
        onConfirm={onForceToManual}
        saving={forceSaving}
      />
      <ConfirmModal
        toggle={toggleSaveModal}
        isOpen={saveModal}
        message="Any modifications to the specialty tags, mesh tags, or case labels will be saved. Proceed?"
        onConfirm={onSaveTags}
        saving={tagsSaving}
      />
    </Card>
  );
};

TaggingActionButtons.propTypes = {
  activeCase: PropTypes.object,
  caseLock: PropTypes.object,
  refreshCases: PropTypes.func,
  addedTerms: PropTypes.array,
  removedTerms: PropTypes.array,
  addedSpecialties: PropTypes.array,
  removedSpecialties: PropTypes.array,
  hasTagModifications: PropTypes.bool
};
