import React from "react";
import PropTypes from "prop-types";
import "./common.scss";
import { head, isEmpty } from "lodash";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ProfileImage = (props) => {
  const { username, avatar, tempVerified = false } = props;

  if (!isEmpty(avatar)) {
    return (
      <img
        src={`${avatar}?w=40&h=40&f=crop`}
        className={`profile-img rounded-circle flex-shrink-0`}
        alt={`Profile ${username}`}
      />
    );
  }

  const firstLetter = head(username);

  return (
    <div className="profile-img-placeholder rounded-circle flex-shrink-0">
      {tempVerified && (
        <div className="temp-verified rounded-circle">
          <FontAwesomeIcon icon={faStar} size={"sm"} />
        </div>
      )}
      {firstLetter}
    </div>
  );
};

ProfileImage.propTypes = {
  username: PropTypes.string,
  avatar: PropTypes.string,
  tempVerified: PropTypes.bool
};

export default ProfileImage;
