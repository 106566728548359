import moment from "moment";

const DB_CONSTANTS = {
  CASE_MEDIA: "caseMediaDB",
  CASES: "casesDB",
  COMMENTS: "commentsDB",
  MESH_TERMS: "meshTermsDB",
  PUBLICATIONS: "publicationsDB",
  USERS: "usersDB",
  USER_ROLES: "userRolesDB",
  ASSIGNMENTS: "clientAssignmentDB",
  REVIEW_MESH_TERMS: "reviewMeshTermsDB",
  GROUPS_DB: "groupsDB",
  REFERENCE_DATA: "b_referenceData",
  REFERENCE_DATA_LATEST: "referenceData",
  CASE_FILTERS: "caseFilters",
  COMMON: "common",
  COUNTRIES: "countries",
  SPECIALTIES: "specialties",
  PROFESSIONS: "professions",
  PLATFORMS: "platforms",
  CASES_V2: "casesDBv2",
  MODERATION: "moderationDB",
  VERIFICATION_TAGS: "verificationTags",
  SCHOOLS: "schools",
  SCHOOLS_BY_PROFESSION: "schools_by_profession",
  CONFIGURATION: "configurationDB",
  ELASTIC_CONFIG: "elasticsearchConfig",
  LABELS: "labels",
  LOCALES: "locales",
  ADMIN_USER_CASE_LOCKS: "adminUserCaseLocksDB",
  ADMIN_CONFIGURATION: "adminConfigurationDB",
  ADMIN_PRIORITY_CONFIGURATION: "verificationPriorityConfig",
  GROUPS: "groups"
};

export const MODERATION_COMMENTS_SUB_COLLECTIONS = {
  REPORTED: {
    statsKey: "reported",
    displayName: "Reported",
    activeAuxClassName: "reported"
  },
  ALERT_WORDS: {
    statsKey: "alerted",
    displayName: "Alert Words",
    activeAuxClassName: "suggested"
  },
  FLAGGED: {
    statsKey: "flagged",
    displayName: "Flagged",
    activeAuxClassName: "flagged"
  },
  ALL: { statsKey: "all", displayName: "All" }
};

export const MODERATION_COMMENTS_ACCEPTED_ANSWER_COLLECTIONS = {
  ACCEPTED: {
    statsKey: "accepted",
    displayName: "Accepted answer",
    activeAuxClassName: "accepted"
  }
};

export const CAMPAIGN_COMMENTS_SUB_COLLECTIONS = {
  QUEUED: {
    statsKey: "pending_approval",
    displayName: "Queued",
    activeAuxClassName: "queued"
  },
  FLAGGED: {
    statsKey: "pending_approval_flagged",
    displayName: "Flagged",
    activeAuxClassName: "flagged"
  }
};

export const MODERATION_COMMENTS_HISTORY_COLLECTIONS = {
  ALL: { statsKey: "all", displayName: "History" }
};

export const MODERATION_COMMENTS_DATE_KEYS = {
  PAST_1_DAY: {
    statAttribute: "past1DayCount",
    displayName: "Past Day",
    getDateFilter: () =>
      moment()
        .subtract(1, "days")
        .format()
  },
  PAST_7_DAY: {
    statAttribute: "past7DayCount",
    displayName: "Past 7 Days",
    getDateFilter: () =>
      moment()
        .subtract(7, "days")
        .format()
  },
  PAST_30_DAY: {
    statAttribute: "past30DayCount",
    displayName: "Past 30 Days",
    getDateFilter: () =>
      moment()
        .subtract(30, "days")
        .format()
  },
  PAST_365_DAY: {
    statAttribute: "past365DayCount",
    displayName: "Past 365 Days",
    getDateFilter: () =>
      moment()
        .subtract(365, "days")
        .format()
  },
  ALL: {
    displayName: "All Time",
    getDateFilter: () =>
      moment()
        .subtract(25, "years")
        .format()
  }
};

export const VERIFICATION_SUB_COLLECTIONS = {
  REVIEW_REQUIRED: {
    statsKey: "review_required",
    displayName: "Temporarily verified"
  },
  CHANGE_REQUESTED: {
    statsKey: "change_requested",
    displayName: "Profession edits"
  },
  PENDING: { statsKey: "pending_manual_verification", displayName: "Pending" },
  UPDATED_INFO: {
    statsKey: "updated_info",
    displayName: "Updated Info",
    isViewable: (userRoles) => {
      return true;
    }
  },
  ONBOARDING_INCOMPLETE: {
    statsKey: "onboarding_incomplete",
    displayName: "Onboarding incomplete"
  },
  INFO_NEEDED: {
    statsKey: "info_needed",
    displayName: "Info Needed"
  },
  VERIFIED: {
    statsKey: "verified",
    displayName: "Verified"
  },
  UNVERIFIABLE: { statsKey: "unverifiable", displayName: "Unverifiable" },
  ALL: { statsKey: "all", displayName: "All" }
};

export default DB_CONSTANTS;
