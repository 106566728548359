import React from "react";
import {
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import PropTypes from "prop-types";
import "./modal.scss";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "../common/LoadingButton";
import { VERIFICATION_STATUSES } from "../../utils/verification-utils";
import { changeVerificationState } from "../../actions/verification.actions";

const VerificationProfessionChangeModal = (props) => {
  const { userUuid, userUid, toggle, isOpen, isRejectMode = false } = props;
  const dispatch = useDispatch();
  const saving = useSelector((state) => state.verification.changeStateSaving);

  const title = isRejectMode
    ? "Reject the profession edit?"
    : "Approve the profession edit?";
  const buttonText = isRejectMode ? "Reject" : "Accept";
  const buttonClass = isRejectMode ? "danger" : "primary";

  const onSubmit = async (e) => {
    e.preventDefault();
    const status = isRejectMode
      ? VERIFICATION_STATUSES.REJECTED.state
      : VERIFICATION_STATUSES.VERIFIED.state;
    await dispatch(changeVerificationState([userUid], userUuid, status));
    toggle();
  };

  const onCancel = async (e) => {
    e.preventDefault();
    toggle();
  };
  const renderBody = () => {
    return (
      <Form onSubmit={onSubmit}>
        <ModalBody></ModalBody>
        <ModalFooter className="border-0 pt-0 pb-4 justify-content-between">
          <Button
            color="primary"
            outline
            onClick={onCancel}
            loading={saving}
            disabled={saving}>
            Cancel
          </Button>

          <LoadingButton
            color={buttonClass}
            type="submit"
            loading={saving}
            disabled={saving}>
            {buttonText}
          </LoadingButton>
        </ModalFooter>
      </Form>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="caption-edit-modal modal-btn-spaced">
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      {renderBody()}
    </Modal>
  );
};

VerificationProfessionChangeModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};

export default VerificationProfessionChangeModal;
