import { get, isEmpty, join, map } from "lodash";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { convertSpecialities } from "../../../utils/tagging-utils";
import { Alert, Button } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { isModerator } from "../../../utils/permission-utils";
import { removeCasePagingStatus } from "../../../actions/moderation.actions";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ConfirmModal from "../../../components/modals/ConfirmModal";

const CasePagingBanner = (props) => {
  const { activeCase } = props;
  const dispatch = useDispatch();

  const taggingAllSpecialties = useSelector(
    (state) => state.reference.taggingAllSpecialties
  );

  const paging = get(activeCase, "isPagingCase", false);
  const userRoles = useSelector((state) => state.user.userRoles);
  const moderator = isModerator(userRoles);
  const [confirmUnpageModal, setConfirmUnpageModal] = useState(false);
  const [saving, setSaving] = useState(false);

  const onUnpage = () => {
    setConfirmUnpageModal(true);
  };

  const onConfirm = async (event) => {
    event.preventDefault();

    const caseUuid = get(activeCase, "caseUuid");
    setSaving(true);
    try {
      await dispatch(removeCasePagingStatus(caseUuid));
    } finally {
      setSaving(false);
      toggleConfirmUnpageModal();
    }
  };

  if (!paging) {
    return null;
  }

  const toggleConfirmUnpageModal = () =>
    setConfirmUnpageModal(!confirmUnpageModal);

  const specialtyUuids = get(activeCase, "specialtyUuids", []);
  const specialties = convertSpecialities(
    specialtyUuids,
    taggingAllSpecialties
  );
  if (isEmpty(specialties)) {
    return null;
  }

  return (
    <>
      <Alert color="light-blue">
        <div className="d-flex flex-nowrap">
          <div className="flex-grow-1">
            Paging -{" "}
            {join(
              map(specialties, (s) => s.name),
              ", "
            )}
          </div>
          {moderator && (
            <Button className="flex-shrink-0 btn-round" onClick={onUnpage}>
              <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
          )}
        </div>
      </Alert>
      <ConfirmModal
        toggle={toggleConfirmUnpageModal}
        isOpen={confirmUnpageModal}
        message={"Remove paging from this case?"}
        confirmButtonText="Remove"
        cancelButtonText="Go back"
        onConfirm={onConfirm}
        saving={saving}
      />
    </>
  );
};

CasePagingBanner.propTypes = {
  activeCase: PropTypes.object
};

export default CasePagingBanner;
