import { combineReducers } from "redux";
import authReducer from "./auth.reducer";
import userReducer from "./user.reducer";
import casesReducer from "./cases.reducer";
import partnerCasesReducer from "./partner-cases.reducer";
import caseReducer from "./case.reducer";
import moderationReducer from "./moderation.reducer";
import anonUserReducer from "./anon-user.reducer";
import referenceReducer from "./reference.reducer";
import usersReducer from "./users.reducer";
import verificationReducer from "./verification.reducer";
import commentsReducer from "./comments.reducer";
import commentsHistoryReducer from "./comments-history.reducer";
import taggingReducer from "./tagging.reducer";
import campaignsReducer from "./campaigns.reducer";
import globalReducer from "./global.reducer";
import caseExplorerReducer from "./case-explorer.reducer";
import promotionReducer from "./promotion.reducer";
import promotionsReducer from "./promotions.reducer";
import toolsReducer from "./tools.reducer";
import profileReducer from "./profile.reducer";
import groupMetaReducer from "./groupMeta.reducer";

const rootReducer = combineReducers({
  anonUser: anonUserReducer,
  auth: authReducer,
  user: userReducer,
  cases: casesReducer,
  case: caseReducer,
  moderation: moderationReducer,
  reference: referenceReducer,
  users: usersReducer,
  verification: verificationReducer,
  comments: commentsReducer,
  commentsHistory: commentsHistoryReducer,
  tagging: taggingReducer,
  partnerCases: partnerCasesReducer,
  campaigns: campaignsReducer,
  global: globalReducer,
  caseExplorer: caseExplorerReducer,
  promotion: promotionReducer,
  promotions: promotionsReducer,
  tools: toolsReducer,
  profile: profileReducer,
  groupMeta: groupMetaReducer
});

export default rootReducer;
