import { isEmpty, isNil, isString, omitBy } from "lodash";
import {
  deleteGroupCall,
  groupSearchCall,
  updateGroupCall
} from "../api/cloud-functions";

const actionsPrefix = "group";

export const CLEAR_GROUP_META_ERROR = `${actionsPrefix}/CLEAR_GROUP_META_ERROR`;
export const UPDATE_GROUP_START = `${actionsPrefix}/UPDATE_GROUP_START`;
export const UPDATE_GROUP_COMPLETE = `${actionsPrefix}/UPDATE_GROUP_COMPLETE`;
export const DELETE_GROUP_START = `${actionsPrefix}/DELETE_GROUP_START`;
export const DELETE_GROUP_COMPLETE = `${actionsPrefix}/DELETE_GROUP_COMPLETE`;
export const GET_ALL_GROUPS_START = `${actionsPrefix}/GET_ALL_GROUPS_START`;
export const GET_ALL_GROUPS_COMPLETE = `${actionsPrefix}/GET_ALL_GROUPS_COMPLETE`;
export const UPDATE_SORT_DIRECTION = `${actionsPrefix}/UPDATE_SORT_DIRECTION`;

export const updateGroup = (groupUuid, groupData) => {
  return async function(dispatch) {
    dispatch({
      type: UPDATE_GROUP_START
    });

    try {
      const response = await updateGroupCall(groupUuid, groupData);
      dispatch({
        type: UPDATE_GROUP_COMPLETE,
        groupUpdateError: !response,
        response
      });

      return response;
    } catch (error) {
      console.log("Error updating group data", error);

      dispatch({
        type: UPDATE_GROUP_COMPLETE,
        groupUpdateError: true
      });

      return false;
    }
  };
};

export const deleteGroup = (groupUuid) => {
  return async function(dispatch, state) {
    dispatch({
      type: DELETE_GROUP_START
    });

    try {
      const response = await deleteGroupCall(groupUuid);
      dispatch({
        type: DELETE_GROUP_COMPLETE,
        groupDeleteError: !response
      });

      return response;
    } catch (error) {
      console.log("Error deleting group data", error);

      dispatch({
        type: UPDATE_GROUP_COMPLETE,
        groupDeleteError: true
      });

      return false;
    }
  };
};

export const fetchGroups = (page) => {
  return async function(dispatch, getState) {
    dispatch({
      type: GET_ALL_GROUPS_START
    });

    const filters = omitBy(
      {
        ...getState().groupMeta.filters
      },
      (v) => isNil(v) || (isString(v) && isEmpty(v))
    );

    const sortDirection = getState().groupMeta.sortDirection;
    const pageSize = getState().groupMeta.pageSize;

    try {
      const results = await groupSearchCall(
        filters,
        page,
        sortDirection,
        pageSize
      );

      dispatch({
        type: GET_ALL_GROUPS_COMPLETE,
        results
      });
    } catch (error) {
      console.log(`Error fetching groups: ${error}`);
      dispatch({
        type: GET_ALL_GROUPS_COMPLETE,
        error: error
      });
    }
  };
};

export const changeSortDirection = (direction) => {
  return async function(dispatch) {
    dispatch({
      type: UPDATE_SORT_DIRECTION,
      direction
    });
    dispatch(fetchGroups(0));
  };
};
