import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import { concat, includes, isEmpty, map, pull, sortBy, uniq } from "lodash";
import PropTypes from "prop-types";
import "./modal.scss";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "../common/LoadingButton";
import {
  addVerificationGlobalTag,
  removeVerificationGlobalTags,
  saveVerificationTags
} from "../../actions/verification.actions";
import Row from "reactstrap/es/Row";
import { faPen, faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getVerificationTagColor } from "../../utils/verification-utils";
import { isVerificationManager } from "../../utils/permission-utils";

const VerificationTagModal = (props) => {
  const {
    userUids,
    userUuids,
    userTags = [],
    isOpen,
    toggle,
    message,
    isError
  } = props;

  const [editUniversalActive, setEditUniversalActive] = useState(false);
  const [formData, setFormData] = useState({
    newTagName: "",
    userTags: [],
    tagsToRemove: []
  });

  const dispatch = useDispatch();
  const allUsers = useSelector((state) => state.verification.users);
  const verificationTags = useSelector(
    (state) => state.reference.verificationTags
  );
  const userRoles = useSelector((state) => state.user.userRoles);
  const saving = useSelector((state) => state.verification.tagsSaving);
  const toggleUniversalEdit = () => {
    setFormData({ ...formData, tagsToRemove: [] });
    setEditUniversalActive(!editUniversalActive);
  };
  const isManager = isVerificationManager(userRoles);

  useEffect(() => {
    setFormData((f) => {
      return { ...f, userTags: map(userTags, (t) => t.tagUuid) };
    });
  }, [JSON.stringify(userTags)]);

  const onSubmit = async (event) => {
    event.preventDefault();

    const saveUserTags = uniq(formData.userTags);
    await dispatch(
      saveVerificationTags(userUids, userUuids, saveUserTags, allUsers)
    );
    toggle();
  };

  const onSubmitRemoveTags = async (event) => {
    event.preventDefault();

    const removeTags = uniq(formData.tagsToRemove);
    await dispatch(removeVerificationGlobalTags(removeTags));
    toggleUniversalEdit();
  };

  const onAddNewTag = async () => {
    if (!isEmpty(formData.newTagName)) {
      await dispatch(addVerificationGlobalTag(formData.newTagName));

      setFormData({ ...formData, newTagName: "" });
    }
  };

  const onAddUserTag = (event) => {
    const { name, value } = event.target;

    setFormData({
      ...formData,
      [name]: uniq(concat(formData[name], value))
    });
  };

  const onRemoveUserTag = (value) => {
    setFormData({
      ...formData,
      userTags: pull(formData.userTags, value)
    });

    return false;
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const onAddTagToRemove = (value) => {
    setFormData({
      ...formData,
      tagsToRemove: uniq(concat(formData.tagsToRemove, value))
    });
  };

  const renderUniversalBody = () => {
    return (
      <Form onSubmit={onSubmitRemoveTags}>
        <ModalHeader toggle={toggle} className="border-0">
          Edit tag list
        </ModalHeader>
        <ModalBody className="pt-0">
          {message && (
            <Alert color={isError ? "danger" : "success"}>{message}</Alert>
          )}
          <div className={"universal-manage-tags mb-5"}>
            <Label className="text-11 text-uppercase font-weight-normal">
              Tags
            </Label>
            {map(sortBy(verificationTags, "name"), (t, index) => {
              if (includes(formData.tagsToRemove, t.tagUuid)) {
                return null;
              }

              return (
                <div
                  key={`tag-${t.tagUuid}`}
                  onClick={() => onAddTagToRemove(t.tagUuid)}
                  className={`d-flex justify-content-between universal-remove-row border-bottom p-2 ${
                    index === 0 ? "border-top" : ""
                  }`}>
                  <div>{t.name}</div>

                  <div>
                    <FontAwesomeIcon
                      icon={faTimes}
                      size={"sm"}
                      className={"text-secondary"}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </ModalBody>
        <ModalFooter className="border-0 pt-0 pb-4 justify-content-between">
          <Button color="primary" outline onClick={toggleUniversalEdit}>
            Cancel
          </Button>

          <LoadingButton
            outline
            color="primary"
            type="submit"
            loading={saving}
            disabled={isEmpty(formData.tagsToRemove) || saving}>
            Save
          </LoadingButton>
        </ModalFooter>
      </Form>
    );
  };

  const renderBody = () => {
    return (
      <Form onSubmit={onSubmit}>
        <ModalHeader toggle={toggle} className="border-0">
          Select/create tags
        </ModalHeader>
        <ModalBody className="pt-0">
          {message && (
            <Alert color={isError ? "danger" : "success"}>{message}</Alert>
          )}
          <div>
            <Label className="text-11 text-uppercase font-weight-normal">
              Tags
            </Label>
          </div>
          <Row>
            {isManager && (
              <FormGroup className="col-7">
                <InputGroup>
                  <Input
                    id="newTagName"
                    name="newTagName"
                    value={formData.newTagName}
                    onChange={onChange}
                    type="text"
                    placeholder="Create tag..."
                    className={"add-remove-icons no-outline rounded-0"}
                  />
                  {!isEmpty(formData.newTagName) && (
                    <InputGroupAddon addonType="append">
                      <Button
                        onClick={onAddNewTag}
                        className="border-right border-top border-bottom rounded-0"
                        color="link">
                        <FontAwesomeIcon icon={faPlus} size={"sm"} />
                      </Button>
                    </InputGroupAddon>
                  )}
                </InputGroup>
              </FormGroup>
            )}
            <FormGroup className={isManager ? "col-5" : "col-12"}>
              <Input
                type="select"
                name="userTags"
                value={""}
                onChange={onAddUserTag}
                className={"no-outline rounded-0"}>
                <option>Select tag</option>
                {map(sortBy(verificationTags, "name"), (t) => {
                  return (
                    <option key={`tag-${t.tagUuid}`} value={t.tagUuid}>
                      {t.name}
                    </option>
                  );
                })}
              </Input>
            </FormGroup>
          </Row>
          <Row>
            <Col className={"added-tag-section overflow-auto"}>
              {!isEmpty(formData.userTags) && (
                <div className={"border pt-3 pb-1 px-3"}>
                  {map(formData.userTags, (t) => {
                    const tag = verificationTags[t];
                    if (!tag) {
                      return null;
                    }

                    return (
                      <Button
                        key={`user-tag-${t}`}
                        color={getVerificationTagColor(tag.name)}
                        size="sm"
                        className={"mr-1 mb-2"}
                        onClick={() => onRemoveUserTag(t)}>
                        {tag.name}{" "}
                        <FontAwesomeIcon
                          icon={faTimes}
                          size={"sm"}
                          className={"ml-1"}
                        />
                      </Button>
                    );
                  })}
                </div>
              )}
            </Col>
          </Row>
          {isManager && (
            <Row>
              <Col className={"pb-1 border-bottom"}>
                <Button color="link" onClick={toggleUniversalEdit}>
                  <FontAwesomeIcon icon={faPen} size={"sm"} className="mr-1" />
                  Edit universal tag list
                </Button>
              </Col>
            </Row>
          )}
        </ModalBody>
        <ModalFooter className="border-0 pt-0 pb-4 justify-content-between">
          <Button color="primary" outline onClick={toggle}>
            Cancel
          </Button>

          <LoadingButton
            outline
            color="primary"
            type="submit"
            loading={saving}
            disabled={saving}>
            Save
          </LoadingButton>
        </ModalFooter>
      </Form>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="tags-modal modal-btn-spaced">
      {!editUniversalActive && renderBody()}
      {editUniversalActive && renderUniversalBody()}
    </Modal>
  );
};

VerificationTagModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  userUids: PropTypes.array,
  userUuids: PropTypes.array,
  userTags: PropTypes.array
};

VerificationTagModal.defaultProps = {};

export default VerificationTagModal;
