import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Card,
  CardImg,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  PopoverBody,
  PopoverHeader,
  Row,
  Spinner,
  UncontrolledPopover
} from "reactstrap";
import PropTypes from "prop-types";
import {
  compact,
  defaultTo,
  find,
  get,
  isArray,
  isEmpty,
  isEqual,
  join,
  map,
  orderBy,
  size,
  sortBy,
  uniq
} from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faEdit,
  faFileUpload,
  faImages,
  faPen,
  faPlusCircle,
  faStar,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import {
  changeRequestActive,
  convertVerificationStatus,
  getDisplayName,
  getProfessionName,
  getSpecialtyName,
  getSubSpecialtyName,
  isLicenseUser,
  isNpiUser,
  isPriorityUser,
  VERIFICATION_STATUSES,
  VERIFICATION_TYPES
} from "../../../utils/verification-utils";
import moment from "moment";
import "./style.scss";
import EditInfoModal from "../../../components/modals/EditInfoModal";
import { useDispatch, useSelector } from "react-redux";
import {
  addVerificationNote,
  disablePriorityFilter,
  enablePriorityFilter,
  filterVerificationUsers,
  saveVerificationTags
} from "../../../actions/verification.actions";
import LoadingButton from "../../../components/common/LoadingButton";
import { getVerificationTags } from "../../../actions/reference.actions";
import VerificationTagModal from "../../../components/modals/VerificationTagModal";
import VerificationFilterModal from "../../../components/modals/VerificationFilterModal";
import VerificationStatusModal from "../../../components/modals/VerificationStatusModal";
import VerificationPhotoViewerModal from "../../../components/modals/VerificationPhotoViewerModal";
import Icon from "../../../components/common/icon";
import VerificationPriorityModal from "../../../components/modals/VerificationPriorityModal";
import { isVerificationManager } from "../../../utils/permission-utils";
import { Typeahead } from "react-bootstrap-typeahead";
import VerificationProfessionChangeModal from "../../../components/modals/VerificationProfessionChangeModal";
import { useGetProfessionStackByTreeUuid } from "../../../utils/reference-utils";
import VerificationProfessionArchiveModal from "../../../components/modals/VerificationProfessionArchiveModal";

export const VerificationUserHeading = (props) => {
  const { userData } = props;

  const countries = useSelector((state) => state.reference.countries);
  const states = useSelector((state) => state.reference.states);

  const [editInfoModal, setEditInfoModal] = useState(false);
  const toggleEditInfoModal = () => setEditInfoModal(!editInfoModal);

  const verificationStatus = convertVerificationStatus(
    get(userData, "verificationStatus", "")
  );

  const userUuid = get(userData, "userUuid", "");
  const verificationType = get(userData, "verificationType", "");
  const npiUser = isNpiUser(verificationType);
  const licenseUser = isLicenseUser(verificationType);
  const email = get(userData, "email", "");
  const username = get(userData, "username", null);
  const npiNumber = defaultTo(get(userData, "npi.npiNumber"), "N/A");
  const license = defaultTo(get(userData, "license.licenseNumber"), "N/A");
  const npiSearchNumber = defaultTo(get(userData, "npi.npiNumber"), null);
  const countryUuid = get(userData, "countryUuid");
  const country = find(countries, (c) => isEqual(c.uuid, countryUuid));
  const filteredStates = country ? get(states, countryUuid, []) : [];
  const stateUuid = get(userData, "stateUuid");
  const location = find(filteredStates, (s) => isEqual(s.uuid, stateUuid));
  const npiDuplicate = get(userData, "npi.npiDuplicatedBy", []);
  const groupUuids = get(userData, "groups", [])?.map(
    (group) => group.groupUuid
  );
  const groupsNames = useSelector((state) => {
    return state.reference?.groups
      ?.filter((group) => groupUuids.indexOf(group.groupUuid) > -1)
      .map((group) => group.groupName)
      .join(", ");
  });

  return (
    <>
      <Row className="justify-content-between m-auto">
        <span className="text-secondary text-uppercase font-weight-bolder">
          STATE:{" "}
          <span>
            {verificationStatus.state ===
            VERIFICATION_STATUSES.CHANGE_REQUESTED.state
              ? VERIFICATION_STATUSES.VERIFIED.displayName
              : verificationStatus.displayName}
          </span>
        </span>
        <span className="d-inline-flex flex-grow-1 flex-nowrap justify-content-end">
          {licenseUser && (
            <a
              href={
                "https://docs.google.com/spreadsheets/d/1w3g-4-4iiDYs0jmvNdKviMpuvnEtpOaZVUMssLRE4Dk/edit#gid=671117887"
              }
              target="_blank"
              rel="noopener noreferrer">
              <FontAwesomeIcon icon={faFileUpload} className="mr-1" />
              Open license in database
            </a>
          )}
          {npiSearchNumber && (
            <a
              href={`https://npiregistry.cms.hhs.gov/provider-view/${npiSearchNumber}`}
              target="_blank"
              rel="noopener noreferrer"
              className="ml-3">
              <FontAwesomeIcon icon={faFileUpload} className="mr-1" />
              Open NPI database
            </a>
          )}
        </span>
      </Row>
      <Row className="justify-content-between mx-auto my-3">
        <div>
          <h5 style={{ fontSize: 18 }} className="mb-0">
            {getDisplayName(userData)}{" "}
            {username && <small> - {`@${username}`}</small>}
          </h5>
          <div>
            <span className="text-11 font-weight-bold text-uppercase">
              UUID:{" "}
            </span>
            <span className="text-secondary text-13">{userUuid}</span>
          </div>
        </div>
        {npiUser && <span>NPI# {npiNumber}</span>}
        {licenseUser && <span>License# {license}</span>}
      </Row>
      <Row className="justify-content-between m-auto">
        <div className="flex-grow-1">
          {country && (
            <span className="text-secondary mr-3">
              {get(country, "name")}
              {!isEmpty(location) && ` • ${get(location, "name")}`}
            </span>
          )}
          <Button
            id="send-email-link"
            className={"align-baseline p-0"}
            color="link">
            {email}
          </Button>
        </div>
        <Button color="link" onClick={toggleEditInfoModal} className={"py-0"}>
          <FontAwesomeIcon icon={faPen} size={"sm"} className="mr-1" />
          Edit info
        </Button>
      </Row>
      {groupsNames && (
        <Row className="justify-content-between m-auto pt-2">
          <Badge className="p-2 bg-other-green text-darkest-gray font-weight-normal">
            Member of {groupsNames} group{groupUuids.length > 1 ? "s" : ""}
          </Badge>
        </Row>
      )}
      {!isEmpty(npiDuplicate) && (
        <Row className="justify-content-between mx-auto my-3">
          <div>
            <h5 style={{ fontSize: 18 }} className="mb-0 removed-color">
              DUPLICATE NPI RECORDS
            </h5>
            {map(npiDuplicate, (dupUuid) => {
              return (
                <div key={`npi-dup-${dupUuid}`}>
                  <span className="text-11 font-weight-bold text-uppercase">
                    UUID:{" "}
                  </span>
                  <span className="text-secondary text-13">{dupUuid}</span>
                </div>
              );
            })}
          </div>
        </Row>
      )}
      <EditInfoModal
        toggle={toggleEditInfoModal}
        isOpen={editInfoModal}
        userData={userData}
      />

      <VerificationCopyEmails users={[userData]} target={"send-email-link"} />
    </>
  );
};

export const VerificationDetails = ({ userData, hideActions = false }) => {
  const verificationType = get(userData, "verificationType", "");
  switch (verificationType) {
    case VERIFICATION_TYPES.NPI.statsKey:
      return (
        <VerificationNpiDetails userData={userData} hideActions={hideActions} />
      );
    case VERIFICATION_TYPES.LICENSE.statsKey:
      return (
        <VerificationLicenseDetails
          userData={userData}
          hideActions={hideActions}
        />
      );
    case VERIFICATION_TYPES.PHOTO.statsKey:
      return (
        <VerificationPhotoDetails
          userData={userData}
          hideActions={hideActions}
        />
      );
    case VERIFICATION_TYPES.INSTITUTIONAL_EMAIL.statsKey:
      return (
        <VerificationInstitutionalEmailDetails
          userData={userData}
          hideActions={hideActions}
        />
      );
    default:
      return null;
  }
};

VerificationDetails.propTypes = {
  userData: PropTypes.object
};

export const VerificationNotes = (props) => {
  const { userData } = props;
  const userUid = get(userData, "userUid");
  const userUuid = get(userData, "userUuid");
  const dispatch = useDispatch();
  const saving = useSelector((state) => state.verification.addNoteSaving);
  const [formData, setFormData] = useState({ text: "" });
  const notes = get(userData, "verificationNotes", []);
  const onSubmit = async (event) => {
    event.preventDefault();
    await dispatch(addVerificationNote(userUid, userUuid, formData.text));

    setFormData({ text: "" });
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const notesTable = compact(
    map(orderBy(notes, ["createdAt"], ["desc"]), (n, index) => {
      const createdAt = moment(get(n, "createdAt")).toDate();
      const formattedDate = Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
        timeZone: "UTC",
        timeZoneName: "short",
        hour: "numeric",
        minute: "numeric"
      }).format(createdAt);

      const isLast = index === size(notes) - 1;
      return (
        <Row
          key={`note-${index}`}
          className={`border-top border-secondary py-2 mx-0 ${
            isLast ? "border-bottom" : null
          }`}>
          <Col className="col-2 border-right border-secondary">
            <span className="font-weight-bold">{get(n, "moderatorName")}</span>
          </Col>
          <Col className="col-4 border-right border-secondary">
            <span className="text-11 text-secondary">
              {" "}
              {formattedDate ? formattedDate : ""}
            </span>
          </Col>
          <Col className="col-6">
            <span className="text-14">{get(n, "text")}</span>
          </Col>
        </Row>
      );
    })
  );

  return (
    <Form onSubmit={onSubmit}>
      {!isEmpty(notes) && (
        <Col sm="12" className="my-4 px-0">
          <div className="overflow-auto" style={{ maxHeight: "180px" }}>
            {notesTable}
          </div>
        </Col>
      )}

      <FormGroup className="py-3">
        <Input
          id="text"
          name="text"
          value={formData.text}
          onChange={onChange}
          type="textarea"
          rows="8"
          placeholder="Enter note here..."
        />
      </FormGroup>
      <Row className="d-flex justify-content-end mx-0 mb-4">
        <LoadingButton
          outline
          color="primary"
          className="ml-2"
          type="submit"
          disabled={!formData.text || saving}
          loading={saving}>
          Add Note
        </LoadingButton>
      </Row>
    </Form>
  );
};

VerificationNotes.propTypes = {
  userData: PropTypes.object
};

export const VerificationHistory = (props) => {
  const { userData } = props;

  const historyData = get(userData, "verificationHistory");

  return (
    <Col sm="12" className="my-4">
      {isEmpty(historyData) && (
        <Row className="border-top border-secondary border-bottom py-2">
          <Col className="col">
            <span className="text-14">No history was found.</span>
          </Col>
        </Row>
      )}

      {!isEmpty(historyData) && (
        <>
          <div className="text-center">
            <VerificationArchiveViewButton userData={userData} />
          </div>
          {map(historyData, (d, index) => {
            const createdAt = get(d, "verificationEventCreatedAt");
            const initiatorUuid = get(d, "verificationEventInitiator");
            return (
              <Row
                key={`history-${index}`}
                className="border-top border-secondary py-2">
                <Col className="col-3 border-right border-secondary">
                  <span className="text-11 text-secondary">
                    {createdAt
                      ? moment(createdAt).format("MMM DD, YYYY h:mm A")
                      : "N/A"}
                  </span>
                </Col>
                <Col className="col-9">
                  <span className="text-14">
                    {get(d, "verificationEventDescription")}
                    {!isEmpty(initiatorUuid) &&
                      !isEqual(initiatorUuid, "None") && (
                        <p className="mb-0 font-weight-500">{`Initiated by: ${initiatorUuid}`}</p>
                      )}
                  </span>
                </Col>
              </Row>
            );
          })}
        </>
      )}
    </Col>
  );
};

VerificationHistory.propTypes = {
  userData: PropTypes.object
};

export const VerificationUserTags = (props) => {
  const { userData } = props;
  const dispatch = useDispatch();
  const [formData, setFormData] = useState([]);
  const allUsers = useSelector((state) => state.verification.users);
  const verificationTags = sortBy(
    useSelector((state) => state.reference.verificationTags),
    "name"
  );
  const saving = useSelector((state) => state.verification.tagsSaving);
  const [tagModal, setTagModal] = useState(false);
  const toggleTagModal = () => setTagModal(!tagModal);
  const [userUid, setUserUid] = useState();
  const [userUuid, setUserUuid] = useState();
  const [userTags, setUserTags] = useState([]);
  const userDataUid = userData?.userUid;
  const userDataUuid = userData?.userUuid;
  const userDataVerificationTags = userData?.verificationTags;

  useEffect(() => {
    setUserUid(userDataUid);
    setUserUuid(userDataUuid);
    setUserTags(userDataVerificationTags || []);
  }, [userDataUid, userDataUuid, userDataVerificationTags]);

  useEffect(() => {
    dispatch(getVerificationTags());
  }, [dispatch]);

  useEffect(() => {
    setFormData(
      map(userTags, (t) => {
        return { tagUuid: t.tagUuid, name: t.tagName };
      })
    );
  }, [JSON.stringify(userTags)]);

  const onChangeTag = (values) => {
    setFormData(values);

    const tagUuids = uniq(map(values, (t) => t.tagUuid));
    dispatch(saveVerificationTags([userUid], [userUuid], tagUuids, allUsers));
  };

  return (
    <Row className="d-flex justify-content-between mb-4">
      <FormGroup className="col-12 mb-0">
        <Label
          className="text-13 text-uppercase font-weight-bold"
          for="tagUuid">
          tags
          <Button
            color="link"
            className="text-13 text-uppercase p-0 m-0 border-0 mx-2 font-weight-bold"
            style={{ verticalAlign: "baseline" }}
            onClick={toggleTagModal}>
            (Edit tags)
          </Button>
          {saving && <Spinner size="sm" color={"primary"} />}
        </Label>
        <Typeahead
          id="tagsTypeahead"
          multiple={true}
          labelKey="name"
          onChange={onChangeTag}
          options={verificationTags}
          placeholder="Select a tag..."
          selected={formData || []}
        />
      </FormGroup>

      <VerificationTagModal
        toggle={toggleTagModal}
        isOpen={tagModal}
        userUids={[userUid]}
        userUuids={[userUuid]}
        userTags={userTags}
      />
    </Row>
  );
};

VerificationUserTags.propTypes = {
  userData: PropTypes.object
};

export const VerificationFilters = (props) => {
  const dispatch = useDispatch();
  const userRoles = useSelector((state) => state.user.userRoles);
  const filters = useSelector((state) => state.verification.filters);
  const priorityEnabled = useSelector(
    (state) => state.verification.priorityEnabled
  );
  const [filterModal, setFilterModal] = useState(false);
  const toggleFilterModal = () => setFilterModal(!filterModal);
  const [priorityModal, setPriorityModal] = useState(false);
  const togglePriorityModal = () => setPriorityModal(!priorityModal);
  const customFilterEnabled = !isEmpty(filters);

  const clearFilters = () => {
    dispatch(filterVerificationUsers({}));
  };

  const onEnablePriority = () => {
    dispatch(enablePriorityFilter());
  };

  const onDisablePriority = () => {
    dispatch(disablePriorityFilter());
  };

  return (
    <>
      <div className="d-flex justify-content-end flex-grow-1 verification-pagination">
        <div className="d-flex flex-column justify-content-end pb-2">
          {isVerificationManager(userRoles) && (
            <div className="pb-1 text-right">
              <div className="cursor-pointer" onClick={togglePriorityModal}>
                <FontAwesomeIcon icon={faEdit} size={"sm"} className="mr-1" />
                Edit priority criteria
              </div>
            </div>
          )}
          <div className="text-right">
            <div
              className="cursor-pointer"
              onClick={priorityEnabled ? onDisablePriority : onEnablePriority}>
              <FontAwesomeIcon
                icon={faPlusCircle}
                size={"sm"}
                className="mr-1"
              />
              {priorityEnabled
                ? "Disable priority filter"
                : "Enable priority filter"}
            </div>

            <>
              <div className="ml-3 cursor-pointer" onClick={toggleFilterModal}>
                <FontAwesomeIcon
                  icon={faPlusCircle}
                  size={"sm"}
                  className="mr-1"
                />
                Filters {!isEmpty(filters) && <Badge>{size(filters)}</Badge>}
              </div>
              {customFilterEnabled && (
                <div
                  className="ml-2 cursor-pointer"
                  color="link"
                  onClick={clearFilters}>
                  Clear
                </div>
              )}
            </>
          </div>
        </div>
      </div>

      <VerificationFilterModal
        toggle={toggleFilterModal}
        isOpen={filterModal}
      />

      <VerificationPriorityModal
        toggle={togglePriorityModal}
        isOpen={priorityModal}
      />
    </>
  );
};

export const VerificationCopyEmails = (props) => {
  const { users, target = "send-email-btn" } = props;
  const emails = map(users, (u) => get(u, "email"));

  return (
    <UncontrolledPopover placement="bottom" trigger="legacy" target={target}>
      <PopoverHeader>Send Email</PopoverHeader>
      <PopoverBody>
        <p>
          <b>Copy the list of email address to your preferred email client:</b>
        </p>
        <p>{join(emails, ", ")}</p>
      </PopoverBody>
    </UncontrolledPopover>
  );
};

VerificationCopyEmails.propTypes = {
  users: PropTypes.array,
  target: PropTypes.string
};

export const VerificationPriorityUserIcon = (props) => {
  const { userData } = props;
  const priorityFilters = useSelector(
    (state) => state.verification.priorityFilters
  );

  if (!isPriorityUser(userData, priorityFilters)) {
    return null;
  }

  return (
    <span className="priority-user-icon">
      <FontAwesomeIcon icon={faStar} />
    </span>
  );
};

VerificationPriorityUserIcon.propTypes = {
  userData: PropTypes.object
};

const VerificationDetailHeader = ({ userData, hideActions = false }) => {
  return (
    <>
      <VerificationCreatedRow userData={userData} />
      <VerificationChangeRequestRow userData={userData} />
      {!hideActions && <VerificationArchiveViewButton userData={userData} />}
    </>
  );
};
const VerificationButtonSection = ({ userData, hideActions = false }) => {
  const hasChangeRequest = changeRequestActive(userData);
  if (hideActions) return null;

  return (
    <>
      <div className="col-border-bottom my-4"></div>
      {hasChangeRequest && (
        <VerificationChangeRequestButtons userData={userData} />
      )}
      <VerificationUserButtons userData={userData} />
    </>
  );
};

const VerificationNpiDetails = ({ userData, hideActions }) => {
  const npiNumber = defaultTo(get(userData, "npi.npiNumber"), "N/A");
  const npiFirstName = defaultTo(get(userData, "npi.npiFirstName"), "N/A");
  const npiLastName = defaultTo(get(userData, "npi.npiLastName"), "N/A");
  const npiMiddleName = defaultTo(get(userData, "npi.npiMiddleName"), "");
  const npiGender = defaultTo(get(userData, "npi.npiGender"), "N/A");
  const npiAddress = defaultTo(get(userData, "npi.npiAddress"), "N/A");
  const npiCity = defaultTo(get(userData, "npi.npiCity"), "N/A");
  const npiState = defaultTo(get(userData, "npi.npiState"), "N/A");
  const npiPostalCode = defaultTo(get(userData, "npi.npiPostalCode"), "N/A");
  const npiCountry = defaultTo(get(userData, "npi.npiCountry"), "N/A");
  const npiEnumerationDate = defaultTo(
    get(userData, "npi.npiEnumerationDate"),
    "N/A"
  );
  const npiDeactivationDate = defaultTo(
    get(userData, "npi.npiDeactivationDate"),
    "N/A"
  );
  const npiReactivationDate = defaultTo(
    get(userData, "npi.npiReactivationDate"),
    "N/A"
  );
  const npiLastUpdate = defaultTo(get(userData, "npi.npiLastUpdate"), "N/A");
  const npiStatus = defaultTo(get(userData, "npi.npiStatus"), "N/A");

  return (
    <Col lg="12" className="mx-0 px-0">
      <VerificationDetailHeader userData={userData} hideActions={hideActions} />

      <Row className="mx-0">
        <Col className="col-6 px-0">
          <VerificationProfessionalInfo userData={userData} />
          <VerificationSchoolInfo userData={userData} />
        </Col>
        <Col className="col-6">
          <strong>NPI Record:</strong>
          <p className={"npi-record"}>
            <b>NPI#:</b> {npiNumber}
            <br />
            <b>First name:</b> {npiFirstName}
            <br />
            <b>Last name:</b> {npiLastName}
            <br />
            <b>Middle name:</b> {npiMiddleName}
            <br />
            <b>Gender:</b> {npiGender}
            <br />
            <b>Address:</b> {npiAddress}
            <br />
            <b>City:</b> {npiCity}
            <br />
            <b>State:</b> {npiState}
            <br />
            <b>Postal Code:</b> {npiPostalCode}
            <br />
            <b>Country:</b> {npiCountry}
            <br />
            <b>Status:</b> {npiStatus}
            <br />
            <b>Enumeration Date:</b> {npiEnumerationDate}
            <br />
            <b>Deactivation Date:</b> {npiDeactivationDate}
            <br />
            <b>Reactivation Date:</b> {npiReactivationDate}
            <br />
            <b>Last Update Date:</b> {npiLastUpdate}
          </p>
        </Col>
      </Row>

      <VerificationButtonSection
        userData={userData}
        hideActions={hideActions}
      />
    </Col>
  );
};

const VerificationLicenseDetails = ({ userData, hideActions }) => {
  return (
    <Col lg="12" className="mx-0 px-0">
      <VerificationDetailHeader userData={userData} hideActions={hideActions} />

      <Row className="mx-0">
        <Col className="col-6 px-0">
          <VerificationProfessionalInfo userData={userData} />
        </Col>
        <Col className="col-6">
          <div className="info-segment">
            <strong>Country of License</strong>
            <p>{get(userData, "license.licenseCountry", "N/A")}</p>
          </div>
          <div className="info-segment">
            <strong>State of License</strong>
            <p>{get(userData, "license.licenseState", "N/A")}</p>
          </div>
          <div className="info-segment">
            <strong>License Number</strong>
            <p>{get(userData, "license.licenseNumber", "N/A")}</p>
          </div>
          <VerificationSchoolInfo userData={userData} />
        </Col>
      </Row>

      <VerificationButtonSection
        userData={userData}
        hideActions={hideActions}
      />
    </Col>
  );
};

const VerificationPhotoDetails = ({ userData, hideActions }) => {
  const [photoModalOpen, setPhotoModalOpen] = useState(false);
  const togglePhotoModal = () => setPhotoModalOpen(!photoModalOpen);

  const verificationPhotos = isArray(get(userData, "verificationPhoto"))
    ? [...get(userData, "verificationPhoto")]
    : [get(userData, "verificationPhoto")];
  const isPhotoSeries = size(verificationPhotos) > 1;
  const firstImageUrl = !isEmpty(verificationPhotos)
    ? `${verificationPhotos[0]}?w=500&fit=crop`
    : null;

  return (
    <Col lg="12" className="mx-0 px-0">
      <VerificationDetailHeader userData={userData} hideActions={hideActions} />

      <Row className="mx-0">
        <Col className="col-6 px-0">
          <Card className="case-detail-body">
            {!isEmpty(verificationPhotos) && (
              <div
                className="position-relative d-flex justify-content-center cursor-pointer"
                key={userData.userUuid}
                onClick={togglePhotoModal}>
                <CardImg
                  top
                  width="100%"
                  src={firstImageUrl}
                  alt="Verification image"
                />
                {isPhotoSeries && (
                  <Button className="btn-detail-body images">
                    <FontAwesomeIcon icon={faImages} color="white" />
                  </Button>
                )}
              </div>
            )}
          </Card>
        </Col>
        <Col className="col-6">
          <VerificationProfessionalInfo userData={userData} />
          <VerificationSchoolInfo userData={userData} />
        </Col>
      </Row>

      <VerificationButtonSection
        userData={userData}
        hideActions={hideActions}
      />
      <VerificationPhotoViewerModal
        isOpen={photoModalOpen}
        toggle={togglePhotoModal}
        media={verificationPhotos}
      />
    </Col>
  );
};

const VerificationInstitutionalEmailDetails = ({ userData, hideActions }) => {
  return (
    <Col lg="12" className="mx-0 px-0">
      <VerificationDetailHeader userData={userData} hideActions={hideActions} />

      <Row className="mx-0">
        <Col className="col">
          <div className="info-segment">
            <strong>Institutional Email</strong>
            <p>{get(userData, "institutionalEmail.email", "N/A")}</p>
          </div>
          <VerificationProfessionalInfo userData={userData} />
          <VerificationSchoolInfo userData={userData} />
        </Col>
      </Row>

      <VerificationButtonSection
        userData={userData}
        hideActions={hideActions}
      />
    </Col>
  );
};

const VerificationUserButtons = (props) => {
  const { userData } = props;
  const userUid = get(userData, "userUid");
  const userUuid = get(userData, "userUuid");
  const verificationStatus = get(userData, "verificationStatus");
  const hasChangeRequest = changeRequestActive(userData);

  const [statusModal, setStatusModal] = useState(false);
  const toggleModal = () => setStatusModal(!statusModal);

  return (
    <Row className="d-flex justify-content-end mx-0 mb-4">
      {!hasChangeRequest && (
        <LoadingButton outline color="primary" onClick={toggleModal}>
          Set verification status
        </LoadingButton>
      )}
      <Button
        id="send-email-single-btn"
        outline
        color="primary"
        className="ml-2">
        Send email
      </Button>

      <VerificationStatusModal
        toggle={toggleModal}
        isOpen={statusModal}
        userUids={[userUid]}
        userUuids={[userUuid]}
        verificationStatus={verificationStatus}
      />

      <VerificationCopyEmails
        users={[userData]}
        target={"send-email-single-btn"}
      />
    </Row>
  );
};

VerificationUserButtons.propTypes = {
  userData: PropTypes.object
};

const VerificationChangeRequestButtons = (props) => {
  const { userData } = props;
  const userUid = get(userData, "userUid");
  const userUuid = get(userData, "userUuid");

  const [showApproveModal, setShowApproveModal] = useState(false);
  const [isRejectMode, setIsRejectMode] = useState(false);
  const toggleModal = (e, rejectMode = false) => {
    setIsRejectMode(rejectMode);
    setShowApproveModal(!showApproveModal);
  };

  return (
    <Row className="d-flex justify-content-end mx-0 mb-4">
      <LoadingButton color="green" onClick={toggleModal} className="mr-2">
        <FontAwesomeIcon
          icon={faCheck}
          size={"sm"}
          color="white"
          className="mr-2"
        />
        Approve
      </LoadingButton>
      <LoadingButton color="danger" onClick={(e) => toggleModal(e, true)}>
        <FontAwesomeIcon
          icon={faTimes}
          size={"sm"}
          color="white"
          className="mr-2"
        />
        Reject
      </LoadingButton>

      <VerificationProfessionChangeModal
        toggle={toggleModal}
        isOpen={showApproveModal}
        userUuid={userUuid}
        userUid={userUid}
        isRejectMode={isRejectMode}
      />
    </Row>
  );
};

VerificationUserButtons.propTypes = {
  userData: PropTypes.object
};

const VerificationArchiveViewButton = ({ userData }) => {
  const { professionChangeRequest } = userData;
  const [showHistoryModal, setShowHistoryModal] = useState(false);

  if (!professionChangeRequest) return null;

  const toggleModal = () => {
    setShowHistoryModal(!showHistoryModal);
  };

  return (
    <>
      <Button color="link" className="p-0 mb-2" onClick={toggleModal}>
        See Verification history
      </Button>

      <VerificationProfessionArchiveModal
        toggle={toggleModal}
        isOpen={showHistoryModal}
        archivedVerifications={userData?.archivedVerification || []}
        userData={userData}
      />
    </>
  );
};

const CustomPropStar = () => {
  return (
    <div style={{ position: "absolute", top: "3px", right: "6px" }}>
      <Icon icon="circle-star" color="red" size="large" />
    </div>
  );
};

const VerificationProfessionalInfo = (props) => {
  const { userData } = props;
  const professionTreeUuid =
    userData?.professionTreeUuid ||
    userData?.professionChangeRequest?.professionTreeUuid;

  const {
    profession: changeProfession,
    specialty: changeSpecialty,
    subspecialty: changeSubSpecialty
  } = useGetProfessionStackByTreeUuid(professionTreeUuid);

  const specialty = changeSpecialty
    ? changeSpecialty.name
    : getSpecialtyName(userData, "N/A");

  const profession = changeProfession
    ? changeProfession.name
    : getProfessionName(userData, "N/A");
  const subspecialty = changeSubSpecialty
    ? changeSubSpecialty.name
    : getSubSpecialtyName(userData, "N/A");

  return (
    <>
      <div className="info-segment">
        <strong>Profession</strong>
        <p>{profession}</p>
      </div>
      <div className="info-segment">
        <strong>Specialty</strong>
        <p>{specialty}</p>
      </div>
      <div className="info-segment">
        <strong>Subspecialties</strong>
        <p>{subspecialty}</p>
      </div>
    </>
  );
};

const VerificationSchoolInfo = (props) => {
  const { userData } = props;
  const customSchool = userData.userCustomSchool;
  const school = customSchool || defaultTo(get(userData, "schoolName"), "N/A");
  const specialtyClass = customSchool
    ? "info-segment alert-danger"
    : "info-segment";

  const [graduationDate, setGraduationDate] = useState("N/A");
  const userGradDate = userData?.graduationDate;

  useEffect(() => {
    if (userGradDate) {
      const splitDate = userGradDate.split("-");
      const tempDate = new Date(
        splitDate[0],
        splitDate[1] - 1,
        splitDate[2],
        0,
        0
      );
      setGraduationDate(
        new Intl.DateTimeFormat("default", {
          month: "long",
          year: "numeric"
        }).format(tempDate)
      );
    }
  }, [userGradDate]);

  return (
    <>
      <div className={specialtyClass}>
        {customSchool && <CustomPropStar />}
        <strong>School</strong>
        <p>{school}</p>
      </div>

      {graduationDate !== "N/A" && (
        <div className="info-segment">
          <strong>Graduation Date</strong>
          <p>{graduationDate}</p>
        </div>
      )}
    </>
  );
};

const VerificationCreatedRow = (props) => {
  const { userData } = props;
  const createdAt = get(userData, "verificationCreatedAt");

  if (isEmpty(createdAt) || !isEmpty(userData?.professionChangeRequest)) {
    return null;
  }

  return (
    <Row className="mx-0 mb-2">
      Verification request submitted on{" "}
      {moment(createdAt).format("MMM DD, YYYY [at] h:mma")}
    </Row>
  );
};

const VerificationChangeRequestRow = (props) => {
  const { userData } = props;
  const createdAt = get(userData, "verificationCreatedAt");

  if (isEmpty(userData?.professionChangeRequest)) {
    return null;
  }

  return (
    <Row className="mx-0 mb-2">
      Profession edit request submitted on{" "}
      {moment(createdAt).format("MMM DD, YYYY [at] h:mma")}
    </Row>
  );
};
