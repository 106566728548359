import React from "react";

const CaseAnonBanner = ({ activeCase }) => {
  if (!activeCase || !activeCase?.isAnonymous) {
    return null;
  }

  return (
    <div className="alert alert-mauve my-2 text-16 text-darkest-gray">
      This case was posted anonymously
    </div>
  );
};

export default CaseAnonBanner;
