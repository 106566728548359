import { get, isNil, size, map, isEqual } from "lodash";
import {
  COMMENT_REFRESH,
  GET_COMMENTS_COMPLETE,
  GET_COMMENTS_START,
  UPDATE_DATE_FILTER,
  UPDATE_STATE_FILTER
} from "../actions/comments.actions";
import {
  MODERATION_COMMENTS_DATE_KEYS,
  MODERATION_COMMENTS_SUB_COLLECTIONS
} from "../db/db-constants";

const INITIAL_STATE = {
  processing: false,
  isError: false,
  message: null,
  stats: null,
  authorMetadata: null,
  stateFilter: MODERATION_COMMENTS_SUB_COLLECTIONS.REPORTED.statsKey,
  dateFilter: MODERATION_COMMENTS_DATE_KEYS.PAST_30_DAY,
  sortDirection: "desc",
  currentPage: 0,
  comments: []
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_COMMENTS_START: {
      return {
        ...state,
        processing: true,
        isError: false,
        comments: [],
        stats: null,
        extraStats: null,
        authorMetadata: null
      };
    }
    case GET_COMMENTS_COMPLETE: {
      const commentsPage = get(action.results, "pageData", []);
      const comments = map(get(action.results, "pageData.hits", []), (c) =>
        get(c, "_source")
      );
      const pageSize = get(commentsPage, "pageSize", 10);
      const totalComments = get(commentsPage, "totalSize", 0);
      const currentPage = action.page || 0;
      const fromPage = currentPage * pageSize + 1;
      const toPage = currentPage * pageSize + size(comments);
      const hasPrevious = currentPage > 0;
      const hasNext = toPage < totalComments;

      return {
        ...state,
        processing: false,
        stats: get(action.results, "allStats.commentsStatusStats", {}),
        extraStats: get(action.results, "allStats.extra", {}),
        authorMetadata: get(action.results, "allStats.authorMetadata", {}),
        comments: comments,
        pageSize: pageSize,
        totalComments,
        fromPage: fromPage,
        toPage: toPage,
        hasPrevious: hasPrevious,
        hasNext: hasNext,
        currentPage: currentPage
      };
    }
    case UPDATE_STATE_FILTER: {
      return {
        ...state,
        stateFilter: action.stateFilter
      };
    }
    case UPDATE_DATE_FILTER: {
      return {
        ...state,
        dateFilter: action.dateFilter
      };
    }
    case COMMENT_REFRESH: {
      if (isNil(action.response)) {
        return state;
      }

      const commentUuid = get(action.response, "commentUuid");
      return {
        ...state,
        comments: map(state.comments, (c) => {
          if (isEqual(commentUuid, c.commentUuid)) {
            return action.response;
          } else {
            return c;
          }
        })
      };
    }
    default:
      return state;
  }
};

export default reducer;
