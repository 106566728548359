// Literally copied from Web and adapted. -- Corey
import React, { useRef, useState } from "react";
import { uniqueId } from "lodash";
import useResizeObserver from "../../hooks/use-resize-observer.hooks";

const TextExpander = ({
  className = "",
  numberOfLines,
  seeMoreText = "Show more",
  seeLessText = "Show less",
  children
}) => {
  /** ********************************** CONFIG ***************************************/
  const paragraphRef = useRef(null);
  const [id] = useState(uniqueId("textExpander"));
  const [isTextTruncated, setIsTextTruncated] = useState(undefined);
  const [isExpanded, setIsExpanded] = useState(false);

  /** ********************************** HOOKS ****************************************/

  useResizeObserver({
    resizeObserverCallback: (entries) => {
      if (!Array.isArray(entries) || !entries.length) {
        return;
      }

      const entry = entries[0];
      setIsTextTruncated(entry.target.scrollHeight > entry.contentRect.height);
    },
    elementRef: paragraphRef
  });

  /** ********************************* FUNCTIONS *************************************/

  const onSeeMoreClick = (e) => {
    setIsExpanded(e.target.checked);
  };

  /** ********************************** RENDER ***************************************/

  return (
    <div>
      <p
        ref={paragraphRef}
        className={`m-0 text-clamp line-height-21 ${className}`}
        style={{
          overflow: "hidden",
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: isExpanded ? "unset" : numberOfLines
        }}>
        {children}
      </p>
      {(isTextTruncated || isExpanded) && (
        <label
          htmlFor={id}
          role="button"
          className="btn-link text-cool-blue cursor-pointer mb-0">
          {isExpanded ? seeLessText : seeMoreText}
          <input
            id={id}
            type="checkbox"
            className="opacity-0 pointer-events-none"
            style={{ opacity: 0 }}
            onChange={onSeeMoreClick}
          />
        </label>
      )}
    </div>
  );
};

export default TextExpander;
