import React, { useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem
} from "reactstrap";
import PropTypes from "prop-types";
import { get, isEqual, join, map } from "lodash";
import { Link } from "react-router-dom";
import "./style.scss";
import "../../../components/navigation/style.scss";
import { useSelector } from "react-redux";
import { formatNumber } from "../../../utils/general-utils";

const CaseLeftNavigation = (props) => {
  const {
    active,
    baseUrl,
    stats,
    extraStats,
    collection,
    extraCollection,
    platform,
    isPartner = false
  } = props;
  const userRoles = useSelector((state) => state.user.userRoles);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleCaseDropdown = () => setDropdownOpen((prevState) => !prevState);
  const mobileLayout = platform === "mobile";

  const generateLinks = (labels, data, ElType) => {
    return map(labels, (value) => {
      if (value.isViewable && !value.isViewable(userRoles)) {
        return null;
      }

      if (value.partnerOnly && !isPartner) {
        return null;
      }

      let count = get(data, value.statsKey, 0);

      // the extra menu's formated a bit differently, so we have to fet the number correctly
      if (count.hasOwnProperty("all")) {
        count = count.all;
      }

      const classNames = ["nav-link"];
      if (isEqual(active, value.statsKey)) {
        classNames.push("active");
      }
      if (count > 0 && value.activeAuxClassName) {
        classNames.push(value.activeAuxClassName);
      }
      return (
        <ElType key={value.statsKey}>
          <Link
            key={`${value.statsKey}-link`}
            className={join(classNames, " ")}
            to={`${baseUrl}/${value.statsKey}`}>
            {value.displayName} ({formatNumber(count)})
          </Link>
        </ElType>
      );
    });
  };

  if (!mobileLayout) {
    const mainLinks = generateLinks(collection, stats, NavItem);
    const extraLinks = generateLinks(extraCollection, extraStats, NavItem);
    return (
      <Nav className="global-left-nav" vertical>
        {mainLinks}
        {extraLinks && (
          <>
            <li>
              <hr />
            </li>
            {extraLinks}
          </>
        )}
      </Nav>
    );
  } else {
    const mainLinks = generateLinks(collection, stats, DropdownItem);
    const extraLinks = generateLinks(extraCollection, extraStats, DropdownItem);
    return (
      <Dropdown
        className="global-left-nav d-sm-block d-md-none mobile w-50"
        isOpen={dropdownOpen}
        toggle={toggleCaseDropdown}>
        <DropdownToggle caret className="w-100 rounded-0 ml-1">
          Select Case
        </DropdownToggle>
        <DropdownMenu>
          {mainLinks}
          {extraLinks && (
            <>
              <li>
                <hr />
              </li>
              {extraLinks}
            </>
          )}
        </DropdownMenu>
      </Dropdown>
    );
  }
};

CaseLeftNavigation.propTypes = {
  baseUrl: PropTypes.string.isRequired,
  active: PropTypes.string,
  collection: PropTypes.object,
  stats: PropTypes.object
};

export default CaseLeftNavigation;
