import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Col, Container, Row, TabContent, TabPane } from "reactstrap";
import SecondaryNavigation from "../../components/navigation/SecondaryNavigation";
import ModerationTabs from "../../components/navigation/ModerationTabs";
import {
  MODERATION_MODULE_NAME,
  MODERATION_SECONDARY_NAVIGATION
} from "../../constants/route-constants";
import { useDispatch, useSelector } from "react-redux";
import {
  MODERATION_CASES,
  MODERATION_PARTNER_CASES
} from "../../constants/routes";
import {
  filterCases,
  getCases,
  getCasesByState,
  setActiveCase,
  setCasesSortDirection
} from "../../actions/cases.actions";
import { find, get, head, isEmpty, isEqual } from "lodash";
import {
  CaseActionButtons,
  CaseAuthorDetail,
  CaseAuthorDetailSmall,
  CaseAuthorMetaData,
  CaseDetailBody,
  CaseFilters,
  CaseLanguage,
  CaseMetaDataSection,
  PartnerCaseButtons
} from "./components/CaseSections";
import CaseLeftNavigation from "./components/CaseLeftNavigation";
import CasePreviewerColumn from "./components/CasePreviewerColumn";
import "../pages.scss";
import { CASE_FILTERS, findCaseLock } from "../../utils/case-utils";
import CasesPaginationRow from "./components/CasesPaginationRow";
import {
  getAllCountriesAndStates,
  getAllGroups,
  getAllLocales,
  getAllProfessionAndSpecialties,
  getAllTaggingSpecialties
} from "../../actions/reference.actions";
import {
  filterPartnerCases,
  getPartnerCases,
  getPartnerCasesByState,
  setActivePartnerCase,
  setPartnerCasesSortDirection
} from "../../actions/partner-cases.actions";
import EmptyContainer from "../../components/common/EmptyContainer";
import { lockCase, removeUserLock } from "../../actions/moderation.actions";
import Loading from "../../components/Loading";

const ModerationLandingPage = (props) => {
  const { activeTab = "cases" } = props;
  const { filterType = CASE_FILTERS.QUEUE.statsKey } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const processing = useSelector((state) => state[activeTab].processing);
  const allLocks = useSelector((state) => state.moderation.allLocks);
  const user = useSelector((state) => state.user.user);
  const cases = useSelector((state) => state[activeTab].cases);
  const activeCase = useSelector((state) => state[activeTab].activeCase);
  const filters = useSelector((state) => state[activeTab].filters);
  const stats = useSelector((state) => state[activeTab].stats);
  const allAuthorMetadata = useSelector(
    (state) => state[activeTab].authorMetadata
  );
  const filter =
    find(CASE_FILTERS, (f) => f.statsKey === filterType) || CASE_FILTERS.QUEUE;
  const isMainCases = isEqual(activeTab, "cases");
  const onGetCases = isMainCases ? getCases : getPartnerCases;
  const onGetCasesByState = isMainCases
    ? getCasesByState
    : getPartnerCasesByState;
  const onFilterCases = isMainCases ? filterCases : filterPartnerCases;
  const onSortCases = isMainCases
    ? setCasesSortDirection
    : setPartnerCasesSortDirection;
  const activeCaseUuid = get(activeCase, "caseUuid");
  const caseLock = findCaseLock(activeCaseUuid, allLocks, user);

  useEffect(() => {
    dispatch(getAllProfessionAndSpecialties());
    dispatch(getAllTaggingSpecialties());
    dispatch(getAllLocales());
    dispatch(getAllCountriesAndStates());
    dispatch(getAllGroups());
  }, [dispatch]);

  useEffect(() => {
    dispatch(onGetCasesByState(filterType, 0));
  }, [location, filterType, dispatch, onGetCasesByState]);

  useEffect(() => {
    if (!caseLock) {
      dispatch(lockCase(user, activeCaseUuid));
    }

    return () => {
      dispatch(removeUserLock(user));
    };
  }, [activeCaseUuid, dispatch, caseLock, user]);

  const onSetActiveCase = (activeCase) => {
    isMainCases
      ? dispatch(setActiveCase(activeCase))
      : dispatch(setActivePartnerCase(activeCase));
  };

  const author = head(get(activeCase, "authors"));
  const authorUuid = get(author, "userUuid");
  const authorMetadata = get(allAuthorMetadata, authorUuid, {});

  const renderInnerBody = () => {
    if (isEmpty(cases)) {
      return (
        <EmptyContainer
          title={`No Cases Found Under "${filter.displayName}"`}
          description="All the cases have have been processed associated with this filter."
        />
      );
    }

    return (
      <Row>
        <Col
          className="case-previewer-column"
          xl="2"
          lg="12"
          md="12"
          style={{ marginTop: 33 }}>
          <CasePreviewerColumn
            cases={cases}
            activeCaseId={activeCaseUuid}
            onSetActiveCase={onSetActiveCase}
          />
        </Col>
        <Col fluid="lg1" xl="7" className="moderation-column-middle">
          <CasesPaginationRow
            stateLocation={activeTab}
            getPage={onGetCases}
            sortCases={onSortCases}
          />
          <CaseAuthorDetailSmall
            activeCase={activeCase}
            author={author}
            authorMetadata={authorMetadata}
          />
          <CaseDetailBody
            activeCase={activeCase}
            refreshCases={onGetCases}
            caseLock={caseLock}
            author={author}
          />
          <CaseActionButtons
            activeCase={activeCase}
            refreshCases={onGetCases}
            caseLock={caseLock}
            classes="d-sm-flex d-md-none"
          />
        </Col>
        <Col
          lg="3"
          className="moderation-column-right d-none d-md-block"
          style={{ marginTop: 26, paddingBottom: 80 }}>
          <CaseActionButtons
            activeCase={activeCase}
            refreshCases={onGetCases}
            caseLock={caseLock}
            classes={""}
          />
          <CaseAuthorDetail author={author} />
          <CaseAuthorMetaData authorMetadata={authorMetadata} />
          <CaseLanguage activeCase={activeCase} caseLock={caseLock} />
          <CaseMetaDataSection activeCase={activeCase} />
          <PartnerCaseButtons
            activeCase={activeCase}
            author={author}
            refreshCases={getPartnerCases}
          />
        </Col>
      </Row>
    );
  };

  const renderBody = () => {
    if (processing) {
      return <Loading />;
    }

    return (
      <Row className="mx-0 mb-4">
        <Col
          className="moderation-left-nav col-border-right py-4 px-0 min-vh-100"
          xl="2"
          lg="2"
          md="2">
          <CaseLeftNavigation
            active={filterType}
            baseUrl={isMainCases ? MODERATION_CASES : MODERATION_PARTNER_CASES}
            stats={stats}
            collection={CASE_FILTERS}
            isPartner={!isMainCases}
          />
        </Col>
        <Col lx="10" lg="10" md="10" className="my-0">
          {renderInnerBody()}
        </Col>
      </Row>
    );
  };

  return (
    <div className="moderation-page">
      <SecondaryNavigation
        links={MODERATION_SECONDARY_NAVIGATION}
        active={MODERATION_MODULE_NAME}
      />
      <div className="mobile-nav-components d-inline-flex d-sm-inline-flex d-md-none d-lg-none d-xl-none w-100 border-top border-dark py-2">
        <ModerationTabs active={activeTab} platform="mobile" />
        <CaseLeftNavigation
          platform={"mobile"}
          active={filterType}
          baseUrl={isMainCases ? MODERATION_CASES : MODERATION_PARTNER_CASES}
          stats={stats}
          collection={CASE_FILTERS}
          isPartner={!isMainCases}
        />
      </div>
      <Container className="moderation-page-container">
        <Row className="m-auto">
          <ModerationTabs active={activeTab} />
          <CaseFilters filters={filters} applyFilters={onFilterCases} />
        </Row>
        <TabContent className="bg-white" activeTab={"1"}>
          <TabPane tabId="1" className="min-vh-100">
            {renderBody()}
          </TabPane>
        </TabContent>
      </Container>
    </div>
  );
};

export default ModerationLandingPage;
