import React, { useState } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import "./style.scss";
import moment from "moment";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from "reactstrap";
import EditGroupModal from "./EditGroupModal";
import DeleteGroupModal from "./DeleteGroupModal";
import { useUserPermissions } from "../../../utils/permission-utils";
import TextExpander from "../../../components/common/TextExpander";

export const GroupsTableHeading = (props) => {
  return (
    <thead>
      <tr>
        <th className="table-heading-style">group uuid</th>
        <th className="table-heading-style">group name</th>
        <th className="table-heading-style">description</th>
        <th className="table-heading-style">group type</th>
        <th className="table-heading-style">created by</th>
        <th className="table-heading-style">created on</th>
        <th className="table-heading-style">members</th>
        <th className="table-heading-style">pending invites</th>
        <th style={{ width: "15%" }} className="table-heading-style"></th>
      </tr>
    </thead>
  );
};

export const GroupsTableRow = (props) => {
  const { data } = props;

  const permissions = useUserPermissions();

  const [showEditModal, setShowEditModal] = useState(false);
  const toggleEditModal = () => setShowEditModal(!showEditModal);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleMenu = () => setDropdownOpen(!dropdownOpen);
  const createdDate = get(data, "groupCreatedAt")
    ? moment(get(data, "groupCreatedAt"))
        .utc()
        .format("MM/DD/YYYY")
    : "";

  const creator = get(data, "groupCreator", {});

  const editOption = permissions.isGroupsViewable ? (
    <DropdownItem onClick={toggleEditModal}>Edit</DropdownItem>
  ) : null;

  const deleteOption =
    data.groupType === "user" && permissions.isAdmin ? (
      <DropdownItem className="btn-main-red" onClick={toggleDeleteModal}>
        Delete
      </DropdownItem>
    ) : null;

  return (
    <>
      <tr>
        <td className="table-row-style">{get(data, "groupUuid")}</td>
        <td className="table-row-style border-left-0">
          {get(data, "groupName")}
        </td>
        <td className="table-row-style">
          <TextExpander numberOfLines={2}>
            <span style={{ display: "block", width: "300px" }}>
              {get(data, "groupDescription")}
            </span>
          </TextExpander>
        </td>
        <td className="table-row-style border-left-0">
          {get(data, "groupType")}
        </td>
        <td className="table-row-style">{creator.userEmail}</td>
        <td className="table-row-style">{createdDate}</td>
        <td className="table-row-style">
          {get(data, "groupActiveMemberCount", 0)}
        </td>
        <td className="table-row-style">
          {get(data, "groupMemberFilters", []).length}
        </td>
        <td>
          <Dropdown isOpen={dropdownOpen} toggle={toggleMenu}>
            <DropdownToggle outline caret>
              Actions
            </DropdownToggle>
            <DropdownMenu>
              {editOption}
              {!!editOption && !!deleteOption && <DropdownItem divider />}
              {deleteOption}
            </DropdownMenu>
          </Dropdown>
        </td>
      </tr>
      {showEditModal && (
        <EditGroupModal
          isOpen={showEditModal}
          toggle={toggleEditModal}
          group={data}
        />
      )}
      {showDeleteModal && (
        <DeleteGroupModal
          isOpen={showDeleteModal}
          toggle={toggleDeleteModal}
          group={data}
        />
      )}
    </>
  );
};

GroupsTableRow.propTypes = {
  data: PropTypes.object
};
