import React, { useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  NavItem,
  NavLink,
  Pagination
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import {
  changeSortDirection,
  fetchGroups
} from "../../../actions/group.actions";
import { formatNumber } from "../../../utils/general-utils";

const GroupsNavigationRow = () => {
  const dispatch = useDispatch();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(!dropdownOpen);
  const sortDirection = useSelector((state) => state.groupMeta?.sortDirection);
  const currentPage = useSelector((state) => state.groupMeta?.currentPage);
  const totalGroups = useSelector((state) => state.groupMeta?.totalGroups);
  const fromPage = useSelector((state) => state.groupMeta?.fromPage);
  const toPage = useSelector((state) => state.groupMeta?.toPage);
  const hasPrevious = useSelector((state) => state.groupMeta?.hasPrevious);
  const hasNext = useSelector((state) => state.groupMeta?.hasNext);

  const changeSortFilter = (newDirection) => {
    dispatch(changeSortDirection(newDirection));
    toggle();
  };

  const onPreviousPage = () => {
    dispatch(fetchGroups(currentPage - 1));
    return false;
  };

  const onNextPage = () => {
    dispatch(fetchGroups(currentPage + 1));
    return false;
  };

  return (
    <div className="d-flex text-secondary mb-3 justify-content-between">
      <div className="case-explorer-pagination d-flex ">
        <span>
          {totalGroups === 0 ? 0 : fromPage}-{toPage} of{" "}
          {formatNumber(totalGroups)}
        </span>
        <Pagination>
          <NavItem>
            <NavLink href="#" disabled={!hasPrevious} onClick={onPreviousPage}>
              <div className="ml-3 mr-2">&#8249;</div>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="#" disabled={!hasNext} onClick={onNextPage}>
              <div className="ml-3 mr-2">&#8250;</div>
            </NavLink>
          </NavItem>
        </Pagination>
      </div>
      <div className="d-flex align-items-center">
        <p className="p-0 m-0 text-14 text-secondary">Sort by:</p>
        <Dropdown className="ml-1" isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle
            className="nav-link text-secondary p-0 text-14"
            color="text-secondary"
            caret>
            {isEqual(sortDirection, "asc")
              ? "Oldest to Newest"
              : "Newest to Oldest"}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem
              onClick={() => changeSortFilter("asc")}
              active={isEqual(sortDirection, "asc")}>
              Oldest to Newest
            </DropdownItem>
            <DropdownItem
              onClick={() => changeSortFilter("desc")}
              active={isEqual(sortDirection, "desc")}>
              Newest to Oldest
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
    </div>
  );
};

export default GroupsNavigationRow;
