import React, { useState } from "react";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from "reactstrap";
import PropTypes from "prop-types";
import "./modal.scss";
import moment from "moment";
import { VerificationDetails } from "../../pages/verification/components/VerificationSections";

const VerificationProfessionArchiveModal = ({
  toggle,
  isOpen,
  archivedVerifications
}) => {
  const isMultipleArchives = archivedVerifications?.length > 1;
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);
  const colSize = isMultipleArchives ? "col-9" : "col-12";

  const onNavClick = (index = 0) => {
    setSelectedItemIndex(index);
  };

  const navColumn = isMultipleArchives ? (
    <Col className="col-3">
      <nav>
        <ul className="list-group">
          {archivedVerifications.map((archivedVerification, i) => {
            return (
              <li
                key={i}
                className={`list-group-item p-0 ${
                  i === selectedItemIndex ? "active" : ""
                }`}>
                <Button
                  color="none"
                  className={`p-2 text-13 ${
                    i === selectedItemIndex ? "text-white" : ""
                  } w-100`}
                  onClick={() => onNavClick(i)}>
                  {moment(archivedVerification.verificationCreatedAt).format(
                    "MM/DD/YYYY h:mma"
                  )}
                </Button>
              </li>
            );
          })}
        </ul>
      </nav>
    </Col>
  ) : null;

  const detailColumn = (
    <Col className={colSize}>
      <VerificationDetails
        userData={archivedVerifications[selectedItemIndex]}
        hideActions={true}
      />
    </Col>
  );
  const renderBody = () => {
    return (
      <ModalBody>
        <Row>
          {navColumn}
          {detailColumn}
        </Row>
      </ModalBody>
    );
  };

  return (
    <Modal
      size="lg"
      isOpen={isOpen}
      toggle={toggle}
      className="modal-btn-spaced">
      <ModalHeader toggle={toggle}>Verification History</ModalHeader>
      {renderBody()}
      <ModalFooter>
        <Button size="lg" color="primary-dark" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

VerificationProfessionArchiveModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};

export default VerificationProfessionArchiveModal;
