import React from "react";

const CaseGroupsBanner = ({ groupDetails }) => {
  if (!groupDetails) {
    return null;
  }

  return (
    <div className="alert bg-other-green my-2 text-16 text-darkest-gray">
      This case was uploaded in {groupDetails.groupName} group
    </div>
  );
};

export default CaseGroupsBanner;
