import React, { useEffect } from "react";
import { Container, Table } from "reactstrap";
import { useLocation } from "react-router-dom";
import { compact, isEmpty, map } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import EmptyContainer from "../../components/common/EmptyContainer";
import Loading from "../../components/Loading";
import {
  GroupsTableHeading,
  GroupsTableRow
} from "./components/GroupsSections";
import GroupsNavigationRow from "./components/GroupsNavigationRow";
import { fetchGroups } from "../../actions/group.actions";

const GroupsLandingPage = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const processing = useSelector((state) => state.groupMeta?.processing);
  const allGroups = useSelector((state) => state.groupMeta?.groups || []);

  useEffect(() => {
    dispatch(fetchGroups(0));
  }, [location, dispatch]);

  const renderBody = () => {
    if (processing) {
      return <Loading />;
    }

    const table = compact(
      map(allGroups, (c, index) => {
        return <GroupsTableRow key={`group-${index}`} data={c} />;
      })
    );

    return (
      <div className="px-4 pt-4 pb-1 bg-white">
        <GroupsNavigationRow />
        {isEmpty(table) && (
          <EmptyContainer
            title={`No Groups Found`}
            description="Please adjust your search criteria to locate your desired groups."
          />
        )}

        {!isEmpty(table) && (
          <div
            className="table-responsive"
            style={{ height: "calc(100vh - 235px)" }}>
            <Table bordered>
              <GroupsTableHeading />
              <tbody>{table}</tbody>
            </Table>
          </div>
        )}
      </div>
    );
  };

  return (
    <Container className="groups force-show-scrollbars mt-4 mb-0">
      <div>{renderBody()}</div>
    </Container>
  );
};

export default GroupsLandingPage;
