import React from "react";
import { Card, CardBody, CardText, CardTitle } from "reactstrap";
import { ReactComponent as DiagnosisIcon } from "../../../assets/images/diagnosis.svg";

const DiagnosisCard = ({ diagnosis }) => {
  return (
    <Card className="border-0" color="lighter-gray">
      <CardBody className="p-3">
        <CardTitle
          tag="p"
          className="d-inline-flex align-items-center font-weight-bold">
          <DiagnosisIcon className="mr-1" />
          Diagnosis added
        </CardTitle>
        <CardText>{diagnosis}</CardText>
      </CardBody>
    </Card>
  );
};

export default DiagnosisCard;
