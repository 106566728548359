import React, { useEffect, useState } from "react";
import { Button, Modal, ModalHeader } from "reactstrap";
import PropTypes from "prop-types";
import ModalBody from "reactstrap/es/ModalBody";
import { useDispatch, useSelector } from "react-redux";
import ModalFooter from "reactstrap/es/ModalFooter";
import LoadingButton from "../../../components/common/LoadingButton";
import { clone, get, isEmpty, set, trim } from "lodash";
import { onInvalidSubmit } from "../../../utils/form-utils";
import { AvField, AvForm } from "availity-reactstrap-validation";
import {
  CLEAR_GROUP_META_ERROR,
  fetchGroups,
  updateGroup
} from "../../../actions/group.actions";

const EditGroupModal = ({ isOpen, toggle, group }) => {
  const dispatch = useDispatch();
  const processing = useSelector((state) => state.groupMeta?.processing);
  const serverError = useSelector((state) => state.groupMeta?.isError);
  const page = useSelector((state) => state.groupMeta?.currentPage);
  const [error, setError] = useState(false);
  const [formData, setFormData] = useState({
    groupName: get(group, "groupName", ""),
    groupDescription: get(group, "groupDescription", "")
  });

  useEffect(() => {
    if (serverError) {
      dispatch({
        type: CLEAR_GROUP_META_ERROR
      });
    }
  }, [serverError, dispatch]);

  const onChange = (event) => {
    const { name, value } = event.target;
    setFormData(clone(set(formData, name, value)));
    const isValid = !isEmpty(trim(formData.groupName));

    setError(!isValid);
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    const result = await dispatch(updateGroup(group.groupUuid, formData));

    if (result) {
      await dispatch(fetchGroups(page));
      toggle();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="caption-edit-modal modal-btn-spaced">
      <ModalHeader
        close={<></>}
        className="px-5 pt-4 border-0 text-20"
        toggle={toggle}>
        Edit Group
      </ModalHeader>
      <AvForm onValidSubmit={onSubmit} onInvalidSubmit={onInvalidSubmit}>
        <ModalBody className="px-5 py-4">
          {serverError && (
            <div className="alert alert-danger" role="alert">
              An error occurred when saving the data. Please try again.
            </div>
          )}
          <AvField
            id="groupName"
            name="groupName"
            label="group name"
            type="text"
            labelClass="text-11 text-uppercase font-weight-normal"
            value={formData.groupName || ""}
            onChange={onChange}
            required
          />

          <AvField
            id="groupDescription"
            name="groupDescription"
            label="group description"
            placeholder="Group description..."
            type="textarea"
            rows="6"
            labelClass="text-11 text-uppercase font-weight-normal"
            value={formData.groupDescription || ""}
            onChange={onChange}
          />
        </ModalBody>
        <ModalFooter className="border-0 px-5 pb-4">
          <Button size="lg" color="secondary" onClick={toggle}>
            Cancel
          </Button>
          <LoadingButton
            loading={processing}
            disabled={error || processing}
            color="primary-dark"
            size="lg"
            className="ml-3 mr-0"
            type="submit">
            Save
          </LoadingButton>
        </ModalFooter>
      </AvForm>
    </Modal>
  );
};

EditGroupModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  group: PropTypes.object
};

export default EditGroupModal;
