import React from "react";
import { connect } from "react-redux";
import { setAuthUser } from "../../actions/auth.actions";
import Firebase from "../../firebase";

const withAuthentication = (Component) => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);

      this.props.onSetAuthUser(JSON.parse(localStorage.getItem("authUser")));
    }

    componentDidMount() {
      this.listener = Firebase.auth.onAuthStateChanged(
        (authUser) => {
          localStorage.setItem("authUser", JSON.stringify(authUser));
          this.props.onSetAuthUser(authUser);
        },
        () => {
          localStorage.removeItem("authUser");
          this.props.onSetAuthUser(null);
        }
      );
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      return <Component {...this.props} />;
    }
  }

  const mapDispatchToProps = (dispatch) => ({
    onSetAuthUser: (authUser) => dispatch(setAuthUser(authUser))
  });

  return connect(null, mapDispatchToProps)(WithAuthentication);
};

export default withAuthentication;
