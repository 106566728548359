import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import { isEmpty, isEqual, head, map, sortBy } from "lodash";
import PropTypes from "prop-types";
import "./modal.scss";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "../common/LoadingButton";
import { VERIFICATION_STATUSES } from "../../utils/verification-utils";
import {
  changeVerificationState,
  getBulkVerificationUsers
} from "../../actions/verification.actions";

const VerificationStatusModal = (props) => {
  const {
    userUids,
    userUuids,
    verificationStatus,
    isOpen,
    toggle,
    message,
    isError,
    bulkBatchUpdate = false
  } = props;
  const [formData, setFormData] = useState({ verificationStatus: "" });
  const dispatch = useDispatch();
  const saving = useSelector((state) => state.verification.changeStateSaving);

  const filters = useSelector((state) => state.verification.filters);
  const activeVerificationType = useSelector(
    (state) => state.verification.activeVerificationType
  );
  const activeVerificationStatus = useSelector(
    (state) => state.verification.activeVerificationStatus
  );

  useEffect(() => {
    setFormData({ verificationStatus: verificationStatus });
  }, [userUids, verificationStatus]);

  const onSubmit = async (event) => {
    event.preventDefault();

    if (bulkBatchUpdate) {
      const { userUids, userUuids } = await getBulkVerificationUsers(
        filters,
        activeVerificationType,
        activeVerificationStatus
      );

      await dispatch(
        changeVerificationState(
          userUids,
          head(userUuids),
          formData.verificationStatus
        )
      );
    } else {
      await dispatch(
        changeVerificationState(
          userUids,
          head(userUuids),
          formData.verificationStatus
        )
      );
    }

    toggle();
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const renderBody = () => {
    return (
      <Form onSubmit={onSubmit}>
        <ModalBody className="pt-0">
          {message && (
            <Alert color={isError ? "danger" : "success"}>{message}</Alert>
          )}
          <div className="bordered-text font-weight-bolder">
            <div className="w-75">
              Select the desired verification state for the user:
            </div>
          </div>
          <div className="py-4">
            {map(sortBy(VERIFICATION_STATUSES, "displayName"), (value, k) => {
              if (value.hideInStatusModal) return null;

              return (
                <FormGroup key={k} check>
                  <Label check>
                    <Input
                      type="radio"
                      name={"verificationStatus"}
                      value={value.state}
                      checked={isEqual(
                        formData.verificationStatus,
                        value.state
                      )}
                      onChange={onChange}
                    />{" "}
                    {value.displayName}
                  </Label>
                </FormGroup>
              );
            })}
          </div>
        </ModalBody>
        <ModalFooter className="border-0 pt-0 pb-4 justify-content-between">
          <Button color="primary" outline onClick={toggle}>
            Cancel
          </Button>

          <LoadingButton
            outline
            color="primary"
            type="submit"
            loading={saving}
            disabled={isEmpty(formData.verificationStatus) || saving}>
            Update
          </LoadingButton>
        </ModalFooter>
      </Form>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="caption-edit-modal modal-btn-spaced">
      <ModalHeader toggle={toggle} className="border-0">
        {" "}
      </ModalHeader>
      {renderBody()}
    </Modal>
  );
};

VerificationStatusModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  userUids: PropTypes.array,
  userUuids: PropTypes.array,
  verificationStatus: PropTypes.string
};

export default VerificationStatusModal;
