import { get, map, size } from "lodash";
import {
  CLEAR_GROUP_META_ERROR,
  DELETE_GROUP_COMPLETE,
  DELETE_GROUP_START,
  GET_ALL_GROUPS_COMPLETE,
  GET_ALL_GROUPS_START,
  UPDATE_GROUP_COMPLETE,
  UPDATE_GROUP_START,
  UPDATE_SORT_DIRECTION
} from "../actions/group.actions";

const INITIAL_STATE = {
  processing: false,
  isError: false,
  sortDirection: "desc",
  pageSize: 25,
  currentPage: 0,
  totalGroups: 0,
  filters: {},
  groups: []
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CLEAR_GROUP_META_ERROR: {
      return {
        ...state,
        isError: false
      };
    }
    case UPDATE_GROUP_START: {
      return {
        ...state,
        processing: true
      };
    }
    case UPDATE_GROUP_COMPLETE: {
      let newGroups = state.groups;

      if (action.response) {
        newGroups = state.groups.map((group) => {
          if (group.groupUuid === action.response?.groupUuid) {
            return action.response;
          }
          return group;
        });
      }
      return {
        ...state,
        groups: newGroups,
        processing: false,
        isError: action.groupUpdateError || false
      };
    }
    case DELETE_GROUP_START: {
      return {
        ...state,
        processing: true
      };
    }
    case DELETE_GROUP_COMPLETE: {
      return {
        ...state,
        processing: false,
        isError: action.groupDeleteError || false
      };
    }
    case GET_ALL_GROUPS_START: {
      return {
        ...state,
        processing: true,
        isError: false,
        groups: []
      };
    }
    case GET_ALL_GROUPS_COMPLETE: {
      const pageData = get(action.results, "pageData");
      const groups = map(get(pageData, "hits", []), (c) => get(c, "_source"));
      const pageSize = get(pageData, "pageSize", 10);
      const totalGroups = get(pageData, "totalSize", 0);
      const currentPage = get(pageData, "page", 0);
      const fromPage = currentPage * pageSize + 1;
      const toPage = currentPage * pageSize + size(groups);
      const hasPrevious = currentPage > 0;
      const hasNext = toPage < totalGroups;

      return {
        ...state,
        processing: false,
        pageSize: pageSize,
        totalGroups: totalGroups,
        fromPage: fromPage,
        toPage: toPage,
        hasPrevious: hasPrevious,
        hasNext: hasNext,
        currentPage: currentPage,
        groups
      };
    }
    case UPDATE_SORT_DIRECTION: {
      return {
        ...state,
        sortDirection: action.direction
      };
    }
    default:
      return state;
  }
};

export default reducer;
