import React from "react";

const CommentAnonBanner = ({ comment }) => {
  if (!comment || (!comment.isAnonymous && !comment.isCaseAnonymous)) {
    return null;
  }

  let label = comment?.isCaseAnonymous ? (
    <>
      Comment on <strong>anonymous</strong> case
    </>
  ) : null;

  if (comment?.isAnonymous) {
    label = (
      <>
        <strong>Anonymous</strong> comment on <strong>anonymous</strong> case
      </>
    );
  }

  return (
    <div className="mt-2 mb-0 alert alert-mauve text-darkest-gray font-weight-normal px-3 py-2">
      {label}
    </div>
  );
};

export default CommentAnonBanner;
