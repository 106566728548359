import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import UnauthenticatedApp from "./pages/unauthenticated-app";
import ModerationApp from "./pages/moderation-app";
import CampaignApp from "./pages/campaigns-app";
import VerificationApp from "./pages/verification-app";
import AdminApp from "./pages/admin-app";
import HomeApp from "./pages/home-app";
import CaseExplorerApp from "./pages/case-explorer-app";
import ToolsApp from "./pages/tools-app";
import withAuthentication from "./components/session/with-authentication";
import * as ROUTES from "./constants/routes";
import { loadElasticConfiguration } from "./db/configuration-db";
import { get, isEmpty, isFunction, split } from "lodash";
import ElasticSearchConfig from "./utils/ElasticSearchConfig";
import GroupsApp from "./pages/groups-app";

class App extends React.Component {
  componentDidMount() {
    const { user } = this.props;

    if (user) {
      this.loadConfiguration();
    }
  }

  componentWillUnmount() {
    if (isFunction(this.elasticRefListener)) {
      this.elasticRefListener();
    }
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;

    if (user && !prevProps.user) {
      this.loadConfiguration();
    }
  }

  loadConfiguration = () => {
    console.log("Fetching elastic configuration...");
    if (isFunction(this.elasticRefListener)) {
      this.elasticRefListener();
    }

    this.elasticRefListener = loadElasticConfiguration((config) => {
      ElasticSearchConfig.apiToken = get(
        config,
        "elasticsearchAuth.access_token"
      );

      const cloudId = get(config, "elasticsearchCloudId");
      ElasticSearchConfig.endpointUrl = this.getElasticSearchEndpoint(cloudId);
    });
  };

  getElasticSearchEndpoint = (cloudId) => {
    if (!isEmpty(cloudId)) {
      const cloudUrls = Buffer.from(split(cloudId, ":")[1], "base64")
        .toString()
        .split("$");

      return `https://${cloudUrls[1]}.${cloudUrls[0]}`;
    }

    return null;
  };

  render() {
    return (
      <Router>
        <Routing />
      </Router>
    );
  }
}

const Routing = (props) => {
  return (
    <>
      <Switch>
        <Route path={ROUTES.MODERATION_ROOT} component={ModerationApp} />
        <Route path={ROUTES.CAMPAIGN_ROOT} component={CampaignApp} />
        <Route path={ROUTES.VERIFICATION_ROOT} component={VerificationApp} />
        <Route path={ROUTES.ADMIN_ROOT} component={AdminApp} />
        <Route path={ROUTES.HOME_ROOT} component={HomeApp} />
        <Route path={ROUTES.CASE_EXPLORER_ROOT} component={CaseExplorerApp} />
        <Route path={ROUTES.TOOLS_ROOT} component={ToolsApp} />
        <Route path={ROUTES.GROUPS_ROOT} component={GroupsApp} />
        <Route component={UnauthenticatedApp} />
      </Switch>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user
});

const mapDispatchToProps = (dispatch) => ({});

export default compose(
  withAuthentication,
  connect(mapStateToProps, mapDispatchToProps)
)(App);
