import {
  differenceWith,
  head,
  isEmpty,
  isNil,
  isString,
  omit,
  omitBy
} from "lodash";
import {
  deleteUserCall,
  resetUserPasswordCall,
  toolsUserSearchCall,
  updateUserCall,
  groupAddMembersCall,
  groupRemoveMembersCall,
  forceSyncUserCall
} from "../api/cloud-functions";
import { isRequestSuccess } from "../utils/general-utils";
import { showGlobalMessage } from "./global.actions";

const actionsPrefix = "tools";

export const GET_USERS_START = `${actionsPrefix}/GET_USERS_START`;
export const GET_USERS_COMPLETE = `${actionsPrefix}/GET_USERS_COMPLETE`;
export const UPDATE_USER_FILTERS = `${actionsPrefix}/UPDATE_USER_FILTERS`;
export const UPDATE_USER_START = `${actionsPrefix}/UPDATE_USER_START`;
export const UPDATE_USER_COMPLETE = `${actionsPrefix}/UPDATE_USER_COMPLETE`;
export const RESET_USER_PASSWORD_START = `${actionsPrefix}/RESET_USER_PASSWORD_START`;
export const RESET_USER_PASSWORD_COMPLETE = `${actionsPrefix}/RESET_USER_PASSWORD_COMPLETE`;
export const SYNC_USER_START = `${actionsPrefix}/SYNC_USER_START`;
export const SYNC_USER_COMPLETE = `${actionsPrefix}/SYNC_USER_COMPLETE`;

export const getUsers = (page) => {
  return async function(dispatch, getState) {
    dispatch({
      type: GET_USERS_START
    });

    const filters = omitBy(
      {
        ...getState().tools.filters
      },
      (v) => isNil(v) || (isString(v) && isEmpty(v))
    );

    const sortDirection = getState().tools.sortDirection;
    const pageSize = getState().tools.pageSize;

    try {
      const results = await toolsUserSearchCall(
        filters,
        page,
        sortDirection,
        pageSize
      );

      dispatch({
        type: GET_USERS_COMPLETE,
        results,
        page
      });
    } catch (error) {
      console.log("Error retrieving users", error);
      dispatch({
        type: GET_USERS_COMPLETE,
        error: error
      });
    }
  };
};

export const filterUsers = (filters, page = 0) => {
  return async function(dispatch) {
    await dispatch({
      type: UPDATE_USER_FILTERS,
      filters
    });

    dispatch(getUsers(page));
  };
};

export const updateUser = (userUid, userData) => {
  return async function(dispatch, getState) {
    dispatch({
      type: UPDATE_USER_START
    });

    try {
      const user = getState().tools.users.find((u) => u.userUid === userUid);
      if (!user) {
        throw new Error(`Could not locate a user with the uid of ${userUid}.`);
      }

      await updateUserGroups(
        user.userUuid,
        user.groups ?? [],
        userData.groups || []
      );

      const updateUserData = omit(userData, "group");
      const response = await updateUserCall(userUid, updateUserData);
      dispatch({
        type: UPDATE_USER_COMPLETE,
        userSaveError: !response
      });

      return response;
    } catch (error) {
      console.log("Error updating user data", error);

      dispatch({
        type: UPDATE_USER_COMPLETE,
        userSaveError: true
      });

      return false;
    }
  };
};

const updateUserGroups = async (userUuid, currentGroups, newGroups) => {
  const removals = differenceWith(currentGroups, newGroups, groupComparer);
  const additions = differenceWith(newGroups, currentGroups, groupComparer);

  const removalPromises = removals.map((group) => {
    return groupRemoveMembersCall(group.groupUuid, [userUuid]);
  });
  const additionPromises = additions.map((group) => {
    return groupAddMembersCall(group.groupUuid, [userUuid]);
  });

  await Promise.all(removalPromises);
  await Promise.all(additionPromises);
};

const groupComparer = (g1, g2) => g1.groupUuid === g2.groupUuid;

export const clearUserError = () => {
  return async function(dispatch) {
    dispatch({
      type: UPDATE_USER_COMPLETE,
      userSaveError: false
    });
  };
};

export const deleteUser = (userUid) => {
  return async function(dispatch) {
    dispatch({
      type: UPDATE_USER_START
    });

    try {
      await deleteUserCall(userUid);

      dispatch({
        type: UPDATE_USER_COMPLETE
      });

      return true;
    } catch (error) {
      console.log("Error deleting user data", error);

      dispatch({
        type: UPDATE_USER_COMPLETE,
        userSaveError: error
      });

      return false;
    }
  };
};

export const resetUserPassword = (email) => {
  return async function(dispatch) {
    dispatch({
      type: RESET_USER_PASSWORD_START
    });

    try {
      const response = await resetUserPasswordCall(email);

      dispatch({
        type: RESET_USER_PASSWORD_COMPLETE
      });

      if (isRequestSuccess(response) && response.store) {
        return response.store;
      }
      return response;
    } catch (error) {
      console.log("Error resetting user password", error);

      dispatch({
        type: RESET_USER_PASSWORD_COMPLETE,
        userResetError: true
      });

      return null;
    }
  };
};

export const forceSyncUser = (userUid) => {
  return async function(dispatch) {
    dispatch({
      type: SYNC_USER_START
    });

    try {
      const response = await forceSyncUserCall(userUid);
      dispatch({
        type: SYNC_USER_COMPLETE,
        userSyncError: !response
      });

      dispatch(
        showGlobalMessage(
          `The user (${userUid}) was synced successfully.`,
          "Sync Successful",
          false
        )
      );

      return response;
    } catch (error) {
      dispatch(
        showGlobalMessage(
          `User sync failed for ${userUid}`,
          "Sync Failed",
          true
        )
      );

      dispatch({
        type: SYNC_USER_COMPLETE,
        userSyncError: true
      });

      return false;
    }
  };
};
