import React, { useEffect, useState } from "react";
import {
  Button,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader
} from "reactstrap";
import PropTypes from "prop-types";
import ModalBody from "reactstrap/es/ModalBody";
import { useDispatch, useSelector } from "react-redux";
import ModalFooter from "reactstrap/es/ModalFooter";
import LoadingButton from "../../../components/common/LoadingButton";
import { isEqual, toLower, trim } from "lodash";
import {
  CLEAR_GROUP_META_ERROR,
  deleteGroup,
  fetchGroups
} from "../../../actions/group.actions";

const DeleteGroupModal = ({ isOpen, toggle, group }) => {
  const dispatch = useDispatch();
  const processing = useSelector((state) => state.groupMeta?.processing);
  const serverError = useSelector((state) => state.groupMeta?.isError);
  const page = useSelector((state) => state.groupMeta?.currentPage);
  const [confirmGroupName, setConfirmGroupName] = useState("");
  const [error, setError] = useState("");
  const groupName = group?.groupName;

  useEffect(() => {
    if (serverError) {
      dispatch({
        type: CLEAR_GROUP_META_ERROR
      });
    }
  }, [serverError, dispatch]);

  const onChange = (event) => {
    const { value } = event.target;
    setConfirmGroupName(value);

    if (error) {
      setError(false);
    }
  };

  const onDelete = async (event) => {
    event.preventDefault();

    const isValid = isEqual(
      trim(toLower(groupName)),
      trim(toLower(confirmGroupName))
    );

    if (isValid) {
      const result = await dispatch(deleteGroup(group.groupUuid));

      if (result) {
        await dispatch(fetchGroups(page));
        toggle();
      }
    } else {
      setError(true);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="caption-edit-modal modal-btn-spaced">
      <ModalHeader
        close={<></>}
        className="px-4 pt-4 border-0 text-20"
        toggle={toggle}>
        Delete this group?
      </ModalHeader>
      <ModalBody className="px-4 py-4">
        {serverError && (
          <div className="alert alert-danger" role="alert">
            An error occurred while deleting the group. Please try again.
          </div>
        )}

        <div className="text-14 font-weight-bold mb-2">
          To confirm, please type the group name in the field below
        </div>

        <FormGroup>
          <Label
            className="text-11 text-uppercase font-weight-normal"
            for="confirmGroupName">
            {groupName}
          </Label>
          <Input
            name="confirmGroupName"
            value={confirmGroupName}
            onChange={onChange}
            type="text"
            invalid={!!error}
          />
          {error && (
            <FormFeedback>The confirmed group name did not match</FormFeedback>
          )}
        </FormGroup>
      </ModalBody>
      <ModalFooter className="border-0 px-4 pb-4">
        <Button size="lg" color="secondary" onClick={toggle}>
          Cancel
        </Button>
        <LoadingButton
          loading={processing}
          disabled={processing}
          onClick={onDelete}
          color="red"
          size="lg"
          className="ml-3 mr-0">
          Yes, Delete
        </LoadingButton>
      </ModalFooter>
    </Modal>
  );
};

DeleteGroupModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  group: PropTypes.object
};

export default DeleteGroupModal;
