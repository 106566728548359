import { isEmpty, isNil, omitBy, isString } from "lodash";
import { commentSearchCall } from "../api/cloud-functions";
import { MODERATION_COMMENTS_ACCEPTED_ANSWER_COLLECTIONS } from "../db/db-constants";
import { COMMENT_STATES } from "../utils/comment-utils";

const actionsPrefix = "comments";

export const GET_COMMENTS_START = `${actionsPrefix}/GET_COMMENTS_START`;
export const GET_COMMENTS_COMPLETE = `${actionsPrefix}/GET_COMMENTS_COMPLETE`;
export const UPDATE_STATE_FILTER = `${actionsPrefix}/UPDATE_STATE_FILTER`;
export const UPDATE_DATE_FILTER = `${actionsPrefix}/UPDATE_DATE_FILTER`;
export const COMMENT_REFRESH = `${actionsPrefix}/COMMENT_REFRESH`;

export const getComments = (page) => {
  return async function(dispatch, getState) {
    dispatch({
      type: GET_COMMENTS_START
    });

    const filters = omitBy(
      {
        dateFilter: getState().comments.dateFilter.getDateFilter(),
        stateFilter: getState().comments.stateFilter
      },
      (v) => isNil(v) || (isString(v) && isEmpty(v))
    );

    // if we're looking at un-reviewed accepted answers
    if (
      filters.stateFilter ===
      MODERATION_COMMENTS_ACCEPTED_ANSWER_COLLECTIONS.ACCEPTED.statsKey
    ) {
      filters.stateFilter = COMMENT_STATES.APPROVED;
      filters.isAcceptedAnswer = true;
      filters.moderatorReviewed = false;
    }

    const sortDirection = getState().comments.sortDirection;

    try {
      const results = await commentSearchCall(filters, page, sortDirection);

      dispatch({
        type: GET_COMMENTS_COMPLETE,
        results,
        page
      });
    } catch (error) {
      console.log("Error retrieving comments", error);
      dispatch({
        type: GET_COMMENTS_COMPLETE,
        error: error
      });
    }
  };
};

export const getCommentsByState = (stateFilter, dateFilter, page) => {
  return async function(dispatch) {
    await dispatch({
      type: UPDATE_STATE_FILTER,
      stateFilter
    });

    dispatch(getComments(page));
  };
};

export const updateCommentsDateFilter = (dateFilter) => {
  return async function(dispatch) {
    dispatch({
      type: UPDATE_DATE_FILTER,
      dateFilter
    });
  };
};
