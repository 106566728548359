import {
  COUNTRIES_STATES_LOADED,
  LABELS_LOADED,
  PROFESSIONS_SPECIALTIES_LOADED,
  SCHOOLS_LOADED,
  TAGGING_SPECIALTIES_LOADED,
  VERIFICATION_TAGS_LOADED,
  PLATFORMS_LOADED,
  LOCALES_LOADED,
  GROUPS_LOADED
} from "../actions/reference.actions";
import { sortBy, map, uniqBy } from "lodash";

const INITIAL_STATE = {
  professions: [],
  professionCategories: [],
  specialties: [],
  verificationTags: {},
  verificationTagsArray: [],
  countries: [],
  states: [],
  schools: {},
  locales: {},
  sortedLocales: [],
  taggingAllSpecialties: {},
  taggingUploaderSpecialties: [],
  taggingSpecialties: [],
  taggingSubSpecialties: [],
  labels: {},
  sortedLabels: [],
  platforms: [],
  groups: []
};

const referenceReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PROFESSIONS_SPECIALTIES_LOADED: {
      return {
        ...state,
        professions: action.professions,
        specialties: action.specialties,
        professionCategories: sortBy(
          uniqBy(
            map(action.professions, (p) => {
              return { name: p.categoryName, value: p.categoryLabel };
            }),
            "value"
          ),
          "name"
        )
      };
    }
    case VERIFICATION_TAGS_LOADED: {
      return {
        ...state,
        verificationTags: action.verificationTags,
        verificationTagsArray: sortBy(
          map(action.verificationTags, (t) => t),
          ["name"]
        )
      };
    }
    case COUNTRIES_STATES_LOADED: {
      return {
        ...state,
        countries: action.countries,
        states: action.states
      };
    }
    case SCHOOLS_LOADED: {
      return {
        ...state,
        schools: action.schools
      };
    }
    case TAGGING_SPECIALTIES_LOADED: {
      return {
        ...state,
        taggingAllSpecialties: action.allSpecialties,
        taggingUploaderSpecialties: action.uploaderSpecialties,
        taggingSpecialties: action.specialties,
        taggingSubSpecialties: action.subSpecialties
      };
    }
    case LABELS_LOADED: {
      return {
        ...state,
        labels: action.labels,
        sortedLabels: action.sortedLabels
      };
    }
    case PLATFORMS_LOADED: {
      return {
        ...state,
        platforms: action.platforms
      };
    }
    case LOCALES_LOADED: {
      return {
        ...state,
        locales: action.locales,
        sortedLocales: action.sortedLocales
      };
    }
    case GROUPS_LOADED: {
      return {
        ...state,
        groups: action.groups
      };
    }
    default:
      return state;
  }
};

export default referenceReducer;
