import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardText,
  FormGroup,
  Input,
  Label
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImages, faLock } from "@fortawesome/free-solid-svg-icons";
import {
  concat,
  defaultTo,
  filter,
  get,
  head,
  includes,
  isEmpty,
  isEqual,
  isNil,
  map,
  size,
  sortBy,
  union,
  uniq,
  without
} from "lodash";
import { getImageUrl } from "../../../utils/media-utils";
import MediaViewerModal from "../../../components/modals/MediaViewerModal";
import NotesViewerModal from "../../../components/modals/NotesViewerModal";
import {
  filterSpecialities,
  filterTerms,
  isTaggingActive
} from "../../../utils/tagging-utils";
import { AsyncTypeahead, Typeahead } from "react-bootstrap-typeahead";
import { fetchMeshTags } from "../../../utils/autocomplete-utils";
import F1Badge from "../../../components/common/F1Badge";
import moment from "moment";
import CaseCommentsViewerModal from "../../../components/modals/CaseCommentsViewerModal";
import CasePagingBanner from "./CasePagingBanner";
import DiagnosisCard from "./DiagnosisCard";
import CaseGroupsBanner from "./CaseGroupsBanner";
import CaseAnonBanner from "./CaseAnonBanner";
import { CaseClassificationDropdown } from "./CaseClassificationDropdown";

export const TaggingCaseDetailBody = (props) => {
  const { activeCase, caseLock } = props;

  const [mediaModal, setMediaModal] = useState(false);
  const [notesModal, setNotesModal] = useState(false);
  const [commentsModal, setCommentsModal] = useState(false);

  const toggleMediaModal = () => setMediaModal(!mediaModal);
  const toggleNotesModal = () => setNotesModal(!notesModal);
  const toggleCommentsModal = () => setCommentsModal(!commentsModal);

  const caseUuid = get(activeCase, "caseUuid");
  const title = get(activeCase, "title");
  const caption = get(activeCase, "caption");
  const diagnosis = get(head(get(activeCase, "diagnoses", [])), "text");
  const media = sortBy(get(activeCase, "media", []), "displayOrder");
  const isImageSeries = size(media) > 1;
  const firstMedia = head(media);
  const imageSize = 500;
  const imageUrl = getImageUrl(firstMedia, imageSize, imageSize);
  const notesCount = size(get(activeCase, "moderationNotes", []));
  const commentsCount = size(get(activeCase, "comments", []));
  const groupUuid = get(activeCase, "groupUuid");
  const groupDetails = useSelector((state) => {
    return state.reference?.groups?.find(
      (group) => group.groupUuid === groupUuid
    );
  });

  const renderLockDetail = () => {
    return (
      <>
        <p className="mt-2 mb-0">
          <strong>View mode</strong>
        </p>
        <p>
          This case is in use by{" "}
          {defaultTo(get(caseLock, "displayName"), get(caseLock, "firstName"))}
        </p>
        <p className="text-12">
          Locked at{" "}
          {moment(caseLock.lockTime.seconds * 1000).format(
            "h:mm A [on] MMMM Do"
          )}
        </p>
      </>
    );
  };

  return (
    <>
      <CasePagingBanner activeCase={activeCase} />
      <CaseGroupsBanner groupDetails={groupDetails} />
      <CaseAnonBanner activeCase={activeCase} />

      <Card className="case-detail-body">
        {imageUrl && (
          <div
            className="position-relative d-flex justify-content-center"
            style={{
              backgroundImage: `url(${imageUrl})`,
              minHeight: imageSize,
              backgroundSize: "cover"
            }}
            key={get(firstMedia, "mediaUuid")}
            onClick={() => toggleMediaModal(media)}>
            {isImageSeries && (
              <Button className="btn-detail-body images">
                <FontAwesomeIcon icon={faImages} color="white" />
              </Button>
            )}
            {caseLock && (
              <>
                <Button className="btn-detail-body lock">
                  <FontAwesomeIcon icon={faLock} color="white" />
                </Button>
                <div className="image-overlay text-center text-white flex-column">
                  {renderLockDetail()}
                </div>
              </>
            )}
          </div>
        )}
        {!imageUrl && caseLock && (
          <div className="d-flex text-white bg-primary flex-column align-items-center justify-content-center">
            {renderLockDetail()}
          </div>
        )}
        <CardBody className="pt-3">
          <div>
            {title && <CardText className="font-weight-bold">{title}</CardText>}
            <CardText>{caption}</CardText>
            {diagnosis?.length > 0 && (
              <div className="mb-3">
                <DiagnosisCard diagnosis={diagnosis} />
              </div>
            )}
          </div>
          <div className="case-links mt-3 d-xl-flex justify-content-between">
            <Button
              className="px-0 text-center"
              color="link"
              disabled={commentsCount === 0}
              onClick={toggleCommentsModal}>
              See comments ({commentsCount})
            </Button>
            <CaseClassificationDropdown
              activeCase={activeCase}
              caseLock={caseLock}
            />
            <Button
              className="px-0 text-center"
              color="link"
              onClick={toggleNotesModal}>
              Notes ({notesCount})
            </Button>
          </div>
        </CardBody>

        <MediaViewerModal
          toggle={toggleMediaModal}
          isOpen={mediaModal}
          media={media}
          caseUuid={caseUuid}
          caseLock={caseLock}
        />
        <NotesViewerModal
          toggle={toggleNotesModal}
          isOpen={notesModal}
          caseDoc={activeCase}
        />
        <CaseCommentsViewerModal
          toggle={toggleCommentsModal}
          isOpen={commentsModal}
          caseDoc={activeCase}
        />
      </Card>
    </>
  );
};

TaggingCaseDetailBody.propTypes = {
  activeCase: PropTypes.object,
  caseLock: PropTypes.object
};

export const TaggingSpecialtiesFilters = (props) => {
  const {
    activeCase,
    caseLock,
    addedSpecialties,
    setAddedSpecialties,
    removedSpecialties,
    setRemovedSpecialties
  } = props;
  const caseState = get(activeCase, "caseState");
  const taggingActive = isTaggingActive(caseState) && isNil(caseLock);
  const taggingAllSpecialties = useSelector(
    (state) => state.reference.taggingAllSpecialties
  );
  const taggingSpecialties = useSelector((state) =>
    [
      ...state.reference.taggingSpecialties,
      ...state.reference.taggingSubSpecialties
    ].sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    })
  );

  useEffect(() => {
    setAddedSpecialties([]);
    setRemovedSpecialties([]);
  }, [activeCase, setAddedSpecialties, setRemovedSpecialties]);

  const existingSpecialties = get(activeCase, "specialtyUuids", []);
  const existingAndAddedSpecialties = uniq(
    concat(existingSpecialties, addedSpecialties)
  );

  let specialtyRef = null;
  const onChange = (selected) => {
    const addedUuid = get(head(selected), "uuid");
    setAddedSpecialties(uniq(concat(addedSpecialties, addedUuid)));
    setRemovedSpecialties(
      filter(removedSpecialties, (u) => !isEqual(u, addedUuid))
    );

    specialtyRef.clear();
  };

  const onRemove = (uuid) => {
    setRemovedSpecialties(uniq(concat(removedSpecialties, uuid)));
    setAddedSpecialties(filter(addedSpecialties, (u) => !isEqual(u, uuid)));
  };

  const taggedSpecialtyUuids = filterSpecialities(
    existingAndAddedSpecialties,
    removedSpecialties,
    taggingSpecialties,
    taggingAllSpecialties
  );

  const renderNoTags = () => {
    return (
      <p className="text-secondary text-12 pb-0 mb-0">
        No tags have been added.
      </p>
    );
  };

  return (
    <>
      <Card className="mt-3">
        <CardBody className="p-0">
          <FormGroup className="col">
            <Label
              className="text-11 text-uppercase font-weight-bold mb-0"
              for="speciality">
              specialties & subspecialties
            </Label>
            {taggingActive && (
              <Typeahead
                id="speciality"
                className="mt-1"
                ref={(ref) => (specialtyRef = ref)}
                onChange={onChange}
                options={taggingSpecialties}
                labelKey="name"
                placeholder="Add a specialty..."
              />
            )}
          </FormGroup>
        </CardBody>
        <CardBody className="pt-0 px-3 pill-box">
          {isEmpty(taggedSpecialtyUuids) && renderNoTags()}
          {map(taggedSpecialtyUuids, (t) => {
            return (
              <F1Badge
                key={`spec-${t.uuid}`}
                uuid={t.uuid}
                name={t.name}
                className="mr-2 mb-2"
                onRemove={taggingActive ? () => onRemove(t.uuid) : null}
                active={includes(addedSpecialties, t.uuid)}
              />
            );
          })}
        </CardBody>
      </Card>
    </>
  );
};

TaggingSpecialtiesFilters.propTypes = {
  activeCase: PropTypes.object,
  caseLock: PropTypes.object,
  addedSpecialties: PropTypes.array,
  setAddedSpecialties: PropTypes.func,
  removedSpecialties: PropTypes.array,
  setRemovedSpecialties: PropTypes.func
};

export const TaggingMeshFilters = (props) => {
  const {
    activeCase,
    caseLock,
    addedTerms,
    setAddedTerms,
    removedTerms,
    setRemovedTerms
  } = props;
  const caseState = get(activeCase, "caseState");
  const taggingActive = isTaggingActive(caseState) && isNil(caseLock);
  const taggedMeshTerms = get(activeCase, "meshTerms", []);
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setAddedTerms([]);
    setRemovedTerms([]);
  }, [activeCase, setAddedTerms, setRemovedTerms]);

  let termRef = null;
  const onChange = (selected) => {
    const addedTerm = get(head(selected), "label");
    setAddedTerms(uniq(concat(addedTerms, addedTerm)));
    setRemovedTerms(filter(removedTerms, (u) => !isEqual(u, addedTerm)));

    termRef.clear();
  };

  const onRemove = (term) => {
    setRemovedTerms(uniq(concat(removedTerms, term)));
    setAddedTerms(filter(addedTerms, (u) => !isEqual(u, term)));
  };

  const onSearch = async (query) => {
    setLoading(true);
    const response = await fetchMeshTags(query);
    const foundSuggestions = map(
      get(head(get(response, "suggest.suggestion", [])), "options"),
      (s) => {
        return { label: get(s, "text"), id: get(s, "_id") };
      }
    );

    setSuggestions(foundSuggestions);
    setLoading(false);
  };

  const updatedTerms = filterTerms(taggedMeshTerms, addedTerms, removedTerms);

  const renderNoTags = () => {
    return (
      <p className="text-secondary text-12 pb-0 mb-0">
        No tags have been added.
      </p>
    );
  };

  return (
    <Card className="my-3">
      <CardBody className="p-0">
        <FormGroup className="col">
          <Label
            className="text-11 text-uppercase font-weight-bold mb-0"
            for="caption">
            mesh terms
          </Label>
          {taggingActive && (
            <AsyncTypeahead
              id="meshTermsTypeahead"
              className="mt-1"
              ref={(ref) => (termRef = ref)}
              minLength={3}
              isLoading={loading}
              onSearch={onSearch}
              options={suggestions}
              placeholder="Add another tag..."
              onChange={onChange}
              selected={[]}
            />
          )}
        </FormGroup>
      </CardBody>
      <CardBody className="pt-0 px-3 pill-box">
        {isEmpty(updatedTerms) && renderNoTags()}
        {map(updatedTerms, (t, index) => {
          return (
            <F1Badge
              key={`mesh-${index}`}
              uuid={t}
              name={t}
              active={includes(addedTerms, t)}
              className="mr-2 mb-2"
              onRemove={taggingActive ? () => onRemove(t) : null}
            />
          );
        })}
      </CardBody>
    </Card>
  );
};

TaggingMeshFilters.propTypes = {
  activeCase: PropTypes.object,
  caseLock: PropTypes.object,
  addedTerms: PropTypes.array,
  setAddedTerms: PropTypes.func,
  removedTerms: PropTypes.array,
  setRemovedTerms: PropTypes.func
};

export const TaggingLabelsFilters = (props) => {
  const { activeCase, caseLock, caseLabels, setCaseLabels } = props;
  const taggingActive = isNil(caseLock);
  const taggingLabels = useSelector((state) => state.reference.sortedLabels);

  useEffect(() => {
    setCaseLabels(get(activeCase, "labels", []));
  }, [activeCase, setCaseLabels]);

  const onChange = (event) => {
    const { name } = event.target;
    setCaseLabels(
      includes(caseLabels, name)
        ? without(caseLabels, name)
        : union(caseLabels, [name])
    );
  };

  return (
    <Card className="my-3">
      <CardBody className="pt-0 pb-3 px-3">
        <div>
          <Label
            className="text-11 text-uppercase font-weight-bold mb-2"
            for="caption">
            case labels
          </Label>

          {map(taggingLabels, (v, index) => {
            return (
              <FormGroup key={`label-${index}`} check>
                <Label check>
                  <Input
                    type="checkbox"
                    name={v.key}
                    checked={includes(caseLabels, v.key)}
                    disabled={!taggingActive}
                    onChange={onChange}
                  />{" "}
                  {v.value}
                </Label>
              </FormGroup>
            );
          })}
        </div>
      </CardBody>
    </Card>
  );
};

TaggingLabelsFilters.propTypes = {
  activeCase: PropTypes.object,
  caseLock: PropTypes.object,
  caseLabels: PropTypes.array,
  setCaseLabels: PropTypes.func
};
