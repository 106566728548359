import {
  addNoteCall,
  commentAcceptedReviewCall,
  commentSetAcceptedAnswerCall,
  commentStateChangeCaseCall,
  editCaseCall,
  editCaseLanguageCall,
  editCaseMediaCall,
  generalCaseSaveCall,
  reviewEditCall,
  stateChangeCaseCall,
  tagStateChangeCaseCall,
  tineyeCall
} from "../api/cloud-functions";
import { COMMENT_REFRESH, getComments } from "./comments.actions";
import {
  ADD_LOCAL_CONTENT_EDIT,
  ADD_LOCAL_MEDIA_EDIT,
  CASE_REFRESH,
  UPDATE_LOCAL_MEDIA
} from "./cases.actions";
import {
  CASE_REFRESH as PARTNER_CASE_REFRESH,
  PARTNER_ADD_LOCAL_CONTENT_EDIT,
  PARTNER_ADD_LOCAL_MEDIA_EDIT,
  PARTNER_UPDATE_LOCAL_MEDIA
} from "./partner-cases.actions";
import { showGlobalMessage } from "./global.actions";
import {
  lockCaseForUser,
  removeLockForUser,
  subscribeToAdminUserCaseLocks
} from "../db/users-db";
import { reduce } from "lodash";
import { refreshPartnerCase } from "./partner-cases.actions";

const actionsPrefix = "moderation";

export const ADD_NOTE_START = `${actionsPrefix}/ADD_NOTE_START`;
export const ADD_NOTE_COMPLETE = `${actionsPrefix}/ADD_NOTE_COMPLETE`;
export const APPROVE_START = `${actionsPrefix}/APPROVE_START`;
export const APPROVE_COMPLETE = `${actionsPrefix}/APPROVE_COMPLETE`;
export const APPROVE_IMMEDIATE_START = `${actionsPrefix}/APPROVE_IMMEDIATE_START`;
export const APPROVE_IMMEDIATE_COMPLETE = `${actionsPrefix}/APPROVE_IMMEDIATE_COMPLETE`;
export const REPORT_START = `${actionsPrefix}/REPORT_START`;
export const REPORT_COMPLETE = `${actionsPrefix}/REPORT_COMPLETE`;
export const FLAG_START = `${actionsPrefix}/FLAG_START`;
export const FLAG_COMPLETE = `${actionsPrefix}/FLAG_COMPLETE`;
export const REJECT_START = `${actionsPrefix}/REJECT_START`;
export const REJECT_COMPLETE = `${actionsPrefix}/REJECT_COMPLETE`;
export const PARTNER_STATE_CHANGE_START = `${actionsPrefix}/PARTNER_STATE_CHANGE_START`;
export const PARTNER_STATE_CHANGE_COMPLETE = `${actionsPrefix}/PARTNER_STATE_CHANGE_COMPLETE`;
export const SUBMIT_SUGGESTION_START = `${actionsPrefix}/SUBMIT_SUGGESTION_START`;
export const SUBMIT_SUGGESTION_COMPLETE = `${actionsPrefix}/SUBMIT_SUGGESTION_COMPLETE`;
export const EDIT_START = `${actionsPrefix}/EDIT_START`;
export const EDIT_COMPLETE = `${actionsPrefix}/EDIT_COMPLETE`;
export const REVIEW_EDIT_START = `${actionsPrefix}/REVIEW_EDIT_START`;
export const REVIEW_EDIT_COMPLETE = `${actionsPrefix}/REVIEW_EDIT_COMPLETE`;
export const TINEYE_START = `${actionsPrefix}/TINEYE_START`;
export const TINEYE_COMPLETE = `${actionsPrefix}/TINEYE_COMPLETE`;
export const APPROVE_COMMENT_START = `${actionsPrefix}/APPROVE_COMMENT_START`;
export const APPROVE_COMMENT_COMPLETE = `${actionsPrefix}/APPROVE_COMMENT_COMPLETE`;
export const FLAG_COMMENT_START = `${actionsPrefix}/FLAG_COMMENT_START`;
export const FLAG_COMMENT_COMPLETE = `${actionsPrefix}/FLAG_COMMENT_COMPLETE`;
export const REJECT_COMMENT_START = `${actionsPrefix}/REJECT_COMMENT_START`;
export const REJECT_COMMENT_COMPLETE = `${actionsPrefix}/REJECT_COMMENT_COMPLETE`;
export const APPROVE_ACCEPTED_COMMENT_START = `${actionsPrefix}/APPROVE_ACCEPTED_COMMENT_START`;
export const APPROVE_ACCEPTED_COMMENT_COMPLETE = `${actionsPrefix}/APPROVE_ACCEPTED_COMMENT_COMPLETE`;
export const REJECT_ACCEPTED_COMMENT_START = `${actionsPrefix}/REJECT_ACCEPTED_COMMENT_START`;
export const REJECT_ACCEPTED_COMMENT_COMPLETE = `${actionsPrefix}/REJECT_ACCEPTED_COMMENT_COMPLETE`;
export const CASE_LOCKS_LISTENER = `${actionsPrefix}/CASE_LOCKS_LISTENER`;
export const CASE_LOCKS_UPDATED = `${actionsPrefix}/CASE_LOCKS_UPDATED`;

const STATE_CHANGE_ERROR_TITLE = "State Change Error";
const STATE_CHANGE_ERROR_MESSAGE =
  "The attempt to transition the case state failed.  Please try again.";
const COMMENT_STATE_CHANGE_ERROR_TITLE = "Comment State Change Error";
const COMMENT_STATE_CHANGE_ERROR_MESSAGE =
  "The attempt to transition the comment state failed.  Please try again.";

export const addNote = (caseUuid, text) => {
  return async function(dispatch) {
    dispatch({
      type: ADD_NOTE_START
    });

    try {
      const response = await addNoteCall(caseUuid, text);

      sendAllCaseRefresh(dispatch, response);
    } catch (error) {
      console.log("Error saving note", error);
    }

    dispatch({
      type: ADD_NOTE_COMPLETE
    });
  };
};

export const approveCase = (caseUuid, refreshCases) => {
  return async function(dispatch) {
    dispatch({
      type: APPROVE_START
    });

    try {
      await stateChangeCaseCall(caseUuid, "approve");

      dispatch(refreshCases());
    } catch (error) {
      dispatch(
        showGlobalMessage(
          STATE_CHANGE_ERROR_MESSAGE,
          STATE_CHANGE_ERROR_TITLE,
          true,
          error
        )
      );
    }

    dispatch({
      type: APPROVE_COMPLETE
    });
  };
};

export const approveCaseImmediately = (caseUuid, refreshCases) => {
  return async function(dispatch) {
    dispatch({
      type: APPROVE_IMMEDIATE_START
    });

    try {
      await tagStateChangeCaseCall(caseUuid, "mesh/approve");

      dispatch(refreshCases());
    } catch (error) {
      dispatch(
        showGlobalMessage(
          STATE_CHANGE_ERROR_MESSAGE,
          STATE_CHANGE_ERROR_TITLE,
          true,
          error
        )
      );
    }

    dispatch({
      type: APPROVE_IMMEDIATE_COMPLETE
    });
  };
};

export const reportCase = (caseUuid, refreshCases) => {
  return async function(dispatch) {
    dispatch({
      type: REPORT_START
    });

    try {
      await stateChangeCaseCall(caseUuid, "reported");

      dispatch(refreshCases());
    } catch (error) {
      dispatch(
        showGlobalMessage(
          STATE_CHANGE_ERROR_MESSAGE,
          STATE_CHANGE_ERROR_TITLE,
          true,
          error
        )
      );
    }

    dispatch({
      type: REPORT_COMPLETE
    });
  };
};

export const flagCase = (caseUuid, refreshCases) => {
  return async function(dispatch) {
    dispatch({
      type: FLAG_START
    });

    try {
      await stateChangeCaseCall(caseUuid, "flag");

      dispatch(refreshCases());
    } catch (error) {
      dispatch(
        showGlobalMessage(
          STATE_CHANGE_ERROR_MESSAGE,
          STATE_CHANGE_ERROR_TITLE,
          true,
          error
        )
      );
    }

    dispatch({
      type: FLAG_COMPLETE
    });
  };
};

export const rejectCase = (
  caseUuid,
  reason = "insufficient_caption",
  refreshCases
) => {
  return async function(dispatch) {
    dispatch({
      type: REJECT_START
    });

    try {
      await stateChangeCaseCall(caseUuid, "reject", reason);

      dispatch(refreshCases());
    } catch (error) {
      dispatch(
        showGlobalMessage(
          STATE_CHANGE_ERROR_MESSAGE,
          STATE_CHANGE_ERROR_TITLE,
          true,
          error
        )
      );
    }

    dispatch({
      type: REJECT_COMPLETE
    });
  };
};

export const partnerCaseStateChange = (caseUuid, state, refreshCases) => {
  return async function(dispatch) {
    dispatch({
      type: PARTNER_STATE_CHANGE_START
    });

    try {
      await stateChangeCaseCall(caseUuid, state);

      dispatch(refreshCases());
    } catch (error) {
      dispatch(
        showGlobalMessage(
          STATE_CHANGE_ERROR_MESSAGE,
          STATE_CHANGE_ERROR_TITLE,
          true,
          error
        )
      );
    }

    dispatch({
      type: PARTNER_STATE_CHANGE_COMPLETE
    });
  };
};

export const submitSuggestionCase = (caseUuid, refreshCases) => {
  return async function(dispatch) {
    dispatch({
      type: SUBMIT_SUGGESTION_START
    });

    try {
      await stateChangeCaseCall(caseUuid, "edit_suggested");

      dispatch(refreshCases());
    } catch (error) {
      dispatch(
        showGlobalMessage(
          STATE_CHANGE_ERROR_MESSAGE,
          STATE_CHANGE_ERROR_TITLE,
          true,
          error
        )
      );
    }

    dispatch({
      type: SUBMIT_SUGGESTION_COMPLETE
    });
  };
};

export const editCase = (
  caseUuid,
  title,
  caption,
  diagnosis,
  caseClassification,
  suggestEdit = true,
  refreshCases
) => {
  return async function(dispatch, getState) {
    dispatch({
      type: EDIT_START
    });

    try {
      const user = getState().user.user;
      const response = await editCaseCall(
        caseUuid,
        title,
        caption,
        diagnosis,
        caseClassification,
        suggestEdit
      );

      sendAllCaseRefresh(dispatch, response);
      suggestEdit
        ? sendAllAddLocalContent({
            dispatch,
            user,
            caseUuid,
            title,
            caption,
            diagnosis
          })
        : sendAllCaseRefresh(dispatch, response);
    } catch (error) {
      dispatch(
        showGlobalMessage(
          "An error occurred while saving the case.  Please try again.",
          "Case Edit Failed",
          true,
          error
        )
      );
    }

    dispatch({
      type: EDIT_COMPLETE
    });
  };
};

export const editCaseLanguage = (caseUuid, language) => {
  return async function(dispatch) {
    try {
      const response = await editCaseLanguageCall(caseUuid, language);

      sendAllCaseRefresh(dispatch, response);
    } catch (error) {
      console.log("Error saving case language", error);
    }
  };
};

export const editPartnerCaseSettings = (caseUuid, data) => {
  return async function(dispatch) {
    dispatch({
      type: EDIT_START
    });

    try {
      const response = await generalCaseSaveCall(
        caseUuid,
        "partner_settings",
        data
      );

      dispatch(refreshPartnerCase(response));
    } catch (error) {
      dispatch(
        showGlobalMessage(
          "An error occurred while saving the case.  Please try again.",
          "Case Save Failed",
          true,
          error
        )
      );
    }

    dispatch({
      type: EDIT_COMPLETE
    });
  };
};

export const removeCasePagingStatus = (caseUuid) => {
  return async function(dispatch) {
    dispatch({
      type: EDIT_START
    });

    try {
      const response = await generalCaseSaveCall(caseUuid, "remove_paging");

      sendAllCaseRefresh(dispatch, response);
    } catch (error) {
      dispatch(
        showGlobalMessage(
          "An error occurred while removing the paging status. Please try again.",
          "Removing Paging Status Failed",
          true,
          error
        )
      );
    }

    dispatch({
      type: EDIT_COMPLETE
    });
  };
};

function dataURItoBlob(dataURI) {
  let binary = atob(dataURI.split(",")[1]);
  let array = [];
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], { type: "image/jpeg" });
}

export const editCaseMedia = (
  caseUuid,
  mediaUuid,
  suggestedEdit = true,
  base64ImageUrl,
  refreshCases
) => {
  return async function(dispatch, getState) {
    dispatch({
      type: EDIT_START
    });

    try {
      const user = getState().user.user;
      const data = new FormData();
      const picture = dataURItoBlob(base64ImageUrl);
      data.append("picture", picture);
      data.append("caseUuid", caseUuid);
      data.append("mediaUuid", mediaUuid);
      data.append("suggestedEdit", suggestedEdit ? "yes" : "no");
      await editCaseMediaCall(data);
      const editInfo = {
        caseUuid,
        mediaUuid,
        base64ImageUrl,
        userUuid: user.userUuid,
        userUid: user.userUid,
        username: user.username
      };
      suggestedEdit
        ? await sendAllAddLocalMedia({ dispatch, editInfo })
        : await sendAllUpdateLocalMedia({ dispatch, editInfo });
    } catch (error) {
      console.log("Error rejecting case", error);
    }

    dispatch({
      type: EDIT_COMPLETE
    });
  };
};

export const reviewEditCase = (caseUuid, editUuid, action = "approve") => {
  return async function(dispatch) {
    dispatch({
      type: REVIEW_EDIT_START
    });

    try {
      const response = await reviewEditCall(caseUuid, editUuid, action);

      sendAllCaseRefresh(dispatch, response);
    } catch (error) {
      console.log("Error reviewing edit", error);
      dispatch(
        showGlobalMessage(
          "There was an error saving the suggested edit.  Please try again.",
          "Edit Save Error",
          true,
          error
        )
      );
    }

    dispatch({
      type: REVIEW_EDIT_COMPLETE
    });
  };
};

export const getTineyeResults = (url) => {
  return async function(dispatch) {
    dispatch({
      type: TINEYE_START
    });

    try {
      const results = await tineyeCall(url);

      dispatch({
        type: TINEYE_COMPLETE,
        results: results
      });
    } catch (error) {
      console.log("Error calling Tineye", error);

      dispatch({
        type: TINEYE_COMPLETE,
        results: [],
        error: true
      });
    }
  };
};

export const approveComment = (commentUuid) => {
  return async function(dispatch) {
    dispatch({
      type: APPROVE_COMMENT_START
    });

    try {
      const response = await commentStateChangeCaseCall(commentUuid, "approve");

      dispatch({
        type: COMMENT_REFRESH,
        response
      });
    } catch (error) {
      dispatch(
        showGlobalMessage(
          COMMENT_STATE_CHANGE_ERROR_MESSAGE,
          COMMENT_STATE_CHANGE_ERROR_TITLE,
          true,
          error
        )
      );
    }

    dispatch({
      type: APPROVE_COMMENT_COMPLETE
    });
  };
};

export const flagComment = (commentUuid) => {
  return async function(dispatch) {
    dispatch({
      type: FLAG_COMMENT_START
    });

    try {
      const response = await commentStateChangeCaseCall(commentUuid, "flag");

      dispatch({
        type: COMMENT_REFRESH,
        response
      });
    } catch (error) {
      dispatch(
        showGlobalMessage(
          COMMENT_STATE_CHANGE_ERROR_MESSAGE,
          COMMENT_STATE_CHANGE_ERROR_TITLE,
          true,
          error
        )
      );
    }

    dispatch({
      type: FLAG_COMMENT_COMPLETE
    });
  };
};

export const rejectComment = (commentUuid, reason = "not_appropriate") => {
  return async function(dispatch) {
    dispatch({
      type: REJECT_COMMENT_START
    });

    try {
      const response = await commentStateChangeCaseCall(
        commentUuid,
        "reject",
        reason
      );

      dispatch({
        type: COMMENT_REFRESH,
        response
      });
    } catch (error) {
      dispatch(
        showGlobalMessage(
          COMMENT_STATE_CHANGE_ERROR_MESSAGE,
          COMMENT_STATE_CHANGE_ERROR_TITLE,
          true,
          error
        )
      );
    }

    dispatch({
      type: REJECT_COMMENT_COMPLETE
    });
  };
};

export const approveAcceptedAnswer = (commentUuid) => {
  return async function(dispatch) {
    dispatch({
      type: APPROVE_ACCEPTED_COMMENT_START
    });

    try {
      const response = await commentAcceptedReviewCall(commentUuid, "reviewed");

      dispatch({
        type: COMMENT_REFRESH,
        response
      });
      dispatch(getComments(0));
    } catch (error) {
      dispatch(
        showGlobalMessage(
          COMMENT_STATE_CHANGE_ERROR_MESSAGE,
          COMMENT_STATE_CHANGE_ERROR_TITLE,
          true,
          error
        )
      );
    }

    dispatch({
      type: APPROVE_ACCEPTED_COMMENT_COMPLETE
    });
  };
};

export const rejectAcceptedAnswer = (commentUuid) => {
  return async function(dispatch) {
    dispatch({
      type: REJECT_ACCEPTED_COMMENT_START
    });

    try {
      const response = await commentSetAcceptedAnswerCall(commentUuid, false);

      dispatch({
        type: COMMENT_REFRESH,
        response
      });
      dispatch(getComments(0));
    } catch (error) {
      dispatch(
        showGlobalMessage(
          COMMENT_STATE_CHANGE_ERROR_MESSAGE,
          COMMENT_STATE_CHANGE_ERROR_TITLE,
          true,
          error
        )
      );
    }

    dispatch({
      type: REJECT_ACCEPTED_COMMENT_COMPLETE
    });
  };
};

export const startModerationLockListener = () => {
  return async function(dispatch, getState) {
    const existing = getState().verification.lockListener;
    if (typeof existing === "function") {
      return;
    }

    const lockListener = await subscribeToAdminUserCaseLocks((snapshot) => {
      dispatch({
        type: CASE_LOCKS_UPDATED,
        allLocks: reduce(
          snapshot.docs,
          (acc, doc) => {
            return {
              ...acc,
              [doc.id]: doc.data()
            };
          },
          {}
        )
      });
    });

    dispatch({
      type: CASE_LOCKS_LISTENER,
      lockListener: lockListener
    });
  };
};

export const lockCase = (user, caseUuid) => {
  return async function(dispatch) {
    if (user) {
      await lockCaseForUser(user, caseUuid);
    }
  };
};

export const removeUserLock = (user) => {
  return async function(dispatch) {
    if (user) {
      await removeLockForUser(user);
    }
  };
};

const sendAllCaseRefresh = (dispatch, response) => {
  dispatch({
    type: CASE_REFRESH,
    response
  });

  dispatch({
    type: PARTNER_CASE_REFRESH,
    response
  });
};

const sendAllAddLocalContent = ({
  dispatch,
  user,
  caseUuid,
  title,
  caption,
  diagnosis
}) => {
  dispatch({
    type: ADD_LOCAL_CONTENT_EDIT,
    payload: {
      caseUuid,
      title,
      caption,
      diagnosis,
      userUuid: user.userUuid,
      userUid: user.userUid,
      username: user.username
    }
  });

  dispatch({
    type: PARTNER_ADD_LOCAL_CONTENT_EDIT,
    payload: {
      caseUuid,
      title,
      caption,
      diagnosis,
      userUuid: user.userUuid,
      userUid: user.userUid,
      username: user.username
    }
  });
};

const sendAllAddLocalMedia = async ({ dispatch, editInfo }) => {
  await dispatch({
    type: ADD_LOCAL_MEDIA_EDIT,
    payload: editInfo
  });

  await dispatch({
    type: PARTNER_ADD_LOCAL_MEDIA_EDIT,
    payload: editInfo
  });
};

const sendAllUpdateLocalMedia = async ({ dispatch, editInfo }) => {
  await dispatch({
    type: UPDATE_LOCAL_MEDIA,
    payload: editInfo
  });

  await dispatch({
    type: PARTNER_UPDATE_LOCAL_MEDIA,
    payload: editInfo
  });
};
