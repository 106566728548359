import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner
} from "reactstrap";
import { editCase } from "../../../actions/moderation.actions";
import {
  CASE_CLASSIFICATION_NAME,
  CASE_CLASSIFICATION_TYPE
} from "../../../constants/case-constants";
import CaseIcon from "../../../assets/images/clinical_case_icon.svg";
import PostIcon from "../../../assets/images/post_icon.svg";
import { get } from "lodash";

export const CaseClassificationDropdown = (props) => {
  const { activeCase, caseLock } = props;
  const dispatch = useDispatch();
  const [caseTypeDropdownOpen, setCaseTypeDropdownOpen] = useState(false);
  const toggleCaseTypeDropdownOpen = () =>
    setCaseTypeDropdownOpen(!caseTypeDropdownOpen);
  const editSaving = useSelector((state) => state.moderation.editSaving);

  const caseUuid = get(activeCase, "caseUuid");
  const caseType = get(
    activeCase,
    "caseClassification",
    CASE_CLASSIFICATION_TYPE.MEDICAL
  );

  const isMedicalCase = caseType === CASE_CLASSIFICATION_TYPE.MEDICAL;

  const caseTypeLabel = isMedicalCase
    ? CASE_CLASSIFICATION_NAME.MEDICAL
    : CASE_CLASSIFICATION_NAME.NONMEDICAL;

  const caseTypeIcon = isMedicalCase ? CaseIcon : PostIcon;

  const onCaseClassificationChange = async (type) => {
    await dispatch(editCase(caseUuid, null, null, null, type, false));
  };

  return (
    <Dropdown
      isOpen={caseTypeDropdownOpen}
      toggle={toggleCaseTypeDropdownOpen}
      disabled={editSaving}
      className="rounded text-14 ">
      <DropdownToggle
        color="none"
        className="d-inline-flex align-items-center bg-lighter-gray">
        {!editSaving && (
          <>
            <img
              src={caseTypeIcon}
              alt=""
              className="mr-1"
              style={{ height: "22px" }}
            />

            <span className="f1-weight-medium">{caseTypeLabel}</span>

            <FontAwesomeIcon
              size="xs"
              className="ml-1"
              icon={faChevronDown}
              color="lighter-gray"
            />
          </>
        )}
        {editSaving && <Spinner size="sm" color="dark" />}
      </DropdownToggle>
      <DropdownMenu>
        {!isMedicalCase && (
          <DropdownItem
            disabled={caseLock}
            onClick={() => {
              onCaseClassificationChange(CASE_CLASSIFICATION_TYPE.MEDICAL);
            }}>
            Change to Clinical case
          </DropdownItem>
        )}
        {isMedicalCase && (
          <DropdownItem
            disabled={caseLock}
            onClick={() => {
              onCaseClassificationChange(CASE_CLASSIFICATION_TYPE.NONMEDICAL);
            }}>
            Change to Post
          </DropdownItem>
        )}
      </DropdownMenu>
    </Dropdown>
  );
};
