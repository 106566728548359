import { useEffect, useRef } from "react";
import ResizeObserver from "resize-observer-polyfill";

/**
 * React hook to observe and react to resizing events on the given element.
 *
 * @param {{ resizeObserverCallback: ResizeObserverCallback, elementRef: React.MutableRefObject<HTMLElement> }} param0
 */
const useResizeObserver = ({ resizeObserverCallback, elementRef }) => {
  /** ********************************** CONFIG ***************************************/
  const observerRef = useRef(null);
  const onResizeObserverRef = useRef(resizeObserverCallback);
  const currentElement = elementRef && elementRef.current;

  /** ********************************** HOOKS ****************************************/

  useEffect(() => {
    if (observerRef.current && currentElement) {
      observerRef.current.unobserve(currentElement);
    }

    observerRef.current = new ResizeObserver(onResizeObserverRef.current);

    if (observerRef.current && currentElement) {
      observerRef.current.observe(currentElement);
    }

    return () => {
      if (observerRef.current && currentElement) {
        observerRef.current.unobserve(currentElement);
      }
    };
  }, [currentElement]);
};

export default useResizeObserver;
