import { chain, compact, find, isEqual, map } from "lodash";
import { FormGroup, Label } from "reactstrap";
import Select from "react-select";
import React from "react";
import { useSelector } from "react-redux";

export const convertCountries = (countryUuids, allCountries) => {
  return compact(
    map(countryUuids, (uuid) => {
      return find(allCountries, (t) => isEqual(t.uuid, uuid));
    })
  );
};

export const convertCountry = (countryUuid, allCountries) => {
  return find(allCountries, (t) => isEqual(t.uuid, countryUuid));
};

export const convertProfessions = (specialityUuids, allProfessions) => {
  return compact(
    map(specialityUuids, (uuid) => {
      return find(allProfessions, (t) => isEqual(t.uuid, uuid));
    })
  );
};

export const convertSpecialties = (specialityUuids, allSpecialties) => {
  return compact(
    map(specialityUuids, (uuid) => {
      return find(allSpecialties, (t) => isEqual(t.uuid, uuid));
    })
  );
};

export const convertSubSpecialties = (specialityUuids, allSubSpecialties) => {
  return compact(
    map(specialityUuids, (uuid) => {
      return find(allSubSpecialties, (t) => isEqual(t.uuid, uuid));
    })
  );
};

export const findSpecialty = (container, targetId, targetProp) => {
  let returnVal = null;

  if (container) {
    if (targetId) {
      returnVal = container[targetProp].find(
        (specialty) => specialty.uuid === targetId
      );
      return returnVal;
    } else {
      return undefined;
    }
  }
};

export const generateDropdown = (
  target,
  label,
  options,
  placeholder,
  onChange,
  currentValue,
  optionAttrValue = "typeUuid",
  optionAttrLabel = "name",
  isClearable = false
) => {
  return (
    <FormGroup>
      <Label for={target} className="text-11 text-uppercase font-weight-normal">
        {label}
      </Label>
      <Select
        id={target}
        name={target}
        options={options}
        placeholder={placeholder}
        onChange={onChange}
        value={currentValue || ""}
        getOptionLabel={(option) => option[optionAttrLabel]}
        getOptionValue={(option) => option[optionAttrValue]}
        isClearable={isClearable}
      />
    </FormGroup>
  );
};

export const useGetProfessionStackByTreeUuid = (uuid) => {
  // working our way backwards from most to least specific

  return useSelector((state) => {
    const proffs = state.reference.professions;
    let profession = null;
    let specialty = null;
    let subspecialty = null;

    if (!uuid) {
      return {
        profession,
        specialty,
        subspecialty
      };
    }

    // find the subspecialty
    profession = proffs.find((p) => {
      specialty = p.specialties?.find((s) => {
        subspecialty = s.subSpecialties?.find((ss) => {
          return ss.uuid === uuid;
        });
        return subspecialty;
      });
      return specialty;
    });

    // find the specialty
    if (!profession) {
      profession = proffs.find((p) => {
        specialty = p.specialties?.find((s) => s.uuid === uuid);
        return specialty;
      });
    }
    if (!profession) {
      profession = proffs.find((p) => p.uuid === uuid);
    }

    return { profession, specialty, subspecialty };
  });
};

export const useGetSpecialtyByTreeUuid = (uuid) => {
  return useSelector((state) => {
    console.log("state.reference.specialties", state.reference.specialties);

    return state.reference.specialties.find((s) => {
      return s.treeUuid === uuid;
    });
  });
};
