import React, { useState } from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardText,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import PropTypes from "prop-types";
import {
  get,
  includes,
  isEqual,
  startCase,
  truncate,
  size,
  isEmpty
} from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamation,
  faFlag,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import ConfirmModal from "../../../components/modals/ConfirmModal";
import {
  approveAcceptedAnswer,
  approveComment,
  flagComment,
  rejectAcceptedAnswer
} from "../../../actions/moderation.actions";
import { useDispatch, useSelector } from "react-redux";
import {
  COMMENT_STATES,
  isApprovalActive,
  isFlaggedActive,
  isRejectedActive
} from "../../../utils/comment-utils";
import RejectCommentModal from "../../../components/modals/RejectCommentModal";
import ProfileImage from "../../../components/common/ProfileImage";
import moment from "moment";
import Icon from "../../../components/common/icon";
import CommentReportsModal from "../../../components/modals/CommentReportsModal";
import { getWebCaseUrl } from "../../../utils/case-utils";
import { CAMPAIGN_COMMENTS_SUB_COLLECTIONS } from "../../../db/db-constants";
import CommentsGroupBanner from "./CommentGroupsBanner";
import CommentAnonBanner from "./CommentAnonBanner";
import pendingAcceptedAnswerImg from "../../../assets/images/verification_options_time.svg";
import acceptedAnswerImg from "../../../assets/images/accepted answer.svg";
import {
  CASE_CLASSIFICATION_NAME,
  CASE_CLASSIFICATION_TYPE
} from "../../../constants/case-constants";

import CaseIcon from "../../../assets/images/clinical_case_icon.svg";
import PostIcon from "../../../assets/images/post_icon.svg";

export const CommentActionButtons = (props) => {
  const { activeComment } = props;
  const dispatch = useDispatch();
  const approvedSaving = useSelector(
    (state) => state.moderation.commentApprovedSaving
  );
  const flaggedSaving = useSelector(
    (state) => state.moderation.commentFlaggedSaving
  );
  const [approveModal, setApproveModal] = useState(false);
  const [flagModal, setFlagModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);

  const toggleApproveModal = () => setApproveModal(!approveModal);
  const toggleFlagModal = () => setFlagModal(!flagModal);
  const toggleRejectModal = () => setRejectModal(!rejectModal);

  const commentUuid = get(activeComment, "commentUuid");

  const onApprove = async () => {
    await dispatch(approveComment(commentUuid));
    toggleApproveModal();
  };

  const onFlag = async () => {
    await dispatch(flagComment(commentUuid));
    toggleFlagModal();
  };

  return (
    <Card className="border-0 action-button-card">
      <CardBody className="py-0 d-flex flex-md-column flex-column align-items-start justify-content-center">
        <Button
          style={{ width: 28, height: 28 }}
          className="btn-round"
          color="approved"
          disabled={!isApprovalActive(activeComment)}
          onClick={toggleApproveModal}>
          <Icon
            icon={"verified-checkmark-t15"}
            size={"medium"}
            color={"white"}
          />
        </Button>
        <Button
          style={{ width: 28, height: 28 }}
          className="btn-round my-2"
          color="flagged"
          disabled={!isFlaggedActive(activeComment)}
          onClick={toggleFlagModal}>
          <FontAwesomeIcon icon={faFlag} color="white" size={"sm"} />
        </Button>
        <Button
          style={{ width: 28, height: 28 }}
          className="btn-round btn-removed"
          color="removed"
          disabled={!isRejectedActive(activeComment)}
          onClick={toggleRejectModal}>
          <FontAwesomeIcon icon={faTimes} size={"sm"} color="white" />
        </Button>
      </CardBody>
      <ConfirmModal
        toggle={toggleApproveModal}
        isOpen={approveModal}
        message="This comment will be moved to the 'Approved' state. Proceed?"
        onConfirm={onApprove}
        saving={approvedSaving}
      />
      <ConfirmModal
        toggle={toggleFlagModal}
        isOpen={flagModal}
        message="This comment will be moved to the 'Flagged' state. Proceed?"
        onConfirm={onFlag}
        saving={flaggedSaving}
      />
      <RejectCommentModal
        toggle={toggleRejectModal}
        isOpen={rejectModal}
        comment={activeComment}
      />
    </Card>
  );
};

CommentActionButtons.propTypes = {
  activeComment: PropTypes.object
};

export const ApprovedCommentActionButtons = (props) => {
  const dispatch = useDispatch();
  const { commentUuid } = props.activeComment;

  const [rejectModalOpen, setRejectModalOpen] = useState(false);
  const toggleRejectModal = () => setRejectModalOpen(!rejectModalOpen);

  const onApprove = async () => {
    await dispatch(approveAcceptedAnswer(commentUuid));
  };

  return (
    <Card className="border-0 action-button-card">
      <CardBody className="py-0 d-flex flex-md-column flex-column align-items-start justify-content-center">
        <Button
          color="approved"
          outline
          onClick={onApprove}
          className="d-flex text-nowrap align-items-center text-12 text-left btn-rounded mb-2"
          style={{ width: "100px" }}>
          <Icon
            icon={"verified-checkmark"}
            size="medium"
            color="approved"
            className="mr-2"
          />{" "}
          <span>
            Mark <br />
            Reviewed
          </span>
        </Button>
        <Button
          outline
          color="removed"
          onClick={toggleRejectModal}
          className="d-flex text-nowrap align-items-center text-12 text-left btn-rounded"
          style={{ width: "100px" }}>
          <FontAwesomeIcon
            icon={faTimes}
            size="large"
            color="removed"
            className="mr-2"
          />{" "}
          <span>
            Remove <br />
            answer
          </span>
        </Button>
        <RemoveAcceptedAnswerModal
          isOpen={rejectModalOpen}
          toggle={toggleRejectModal}
          commentUuid={commentUuid}
        />
      </CardBody>
    </Card>
  );
};

export const AcceptedAnswerLabel = (props) => {
  const { comment } = props;
  const isApproved = comment?.moderatorReviewStatus === "reviewed" || false;
  const { isAcceptedAnswer } = comment;

  const [dropdownOpen, setDropdownOpen] = useState();
  const [isModalOpen, setisModalOpen] = useState();

  const toggleRemoveModal = () => {
    setisModalOpen(!isModalOpen);
  };

  if (!isAcceptedAnswer) {
    return null;
  }

  /*
  flagged, reported, pending approval, or deleted
  */
  const icon = isApproved ? acceptedAnswerImg : pendingAcceptedAnswerImg;

  const labelText = (
    <>
      <img
        src={icon}
        className="mr-1"
        style={{ width: 16, position: "absolute", left: 0 }}
      />
      Accepted Answer
    </>
  );

  return (
    <div
      className="rounded d-inline-flex bg-lighter-gray mr-2 pl-1 pr-2 text-11"
      style={{ paddingTop: "2px", paddingBottom: "2px" }}>
      {!isApproved && (
        <span
          className="d-inline-flex align-items-center text-uppercase py-0 pr-0 bg-transparent text-secondary position-relative"
          style={{ paddingLeft: "20px" }}>
          {labelText}
        </span>
      )}

      {isApproved && (
        <>
          <Dropdown
            isOpen={dropdownOpen}
            toggle={() => setDropdownOpen(!dropdownOpen)}
            className="">
            <DropdownToggle
              caret
              color="transparent"
              className="d-inline-flex align-items-center text-uppercase p-0 bg-transparent text-secondary text-11">
              <span className="py-0 pr-0" style={{ paddingLeft: "20px" }}>
                {labelText}
              </span>
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem onClick={toggleRemoveModal}>
                <span>Remove Answer</span>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <RemoveAcceptedAnswerModal
            isOpen={isModalOpen}
            toggle={toggleRemoveModal}
            commentUuid={comment.commentUuid}
          />
        </>
      )}
    </div>
  );
};

AcceptedAnswerLabel.propTypes = {
  show: PropTypes.object
};

export const RemoveAcceptedAnswerModal = (props) => {
  const { isOpen, toggle } = props;
  const dispatch = useDispatch();

  const removeApprovedAnswer = async () => {
    await dispatch(rejectAcceptedAnswer(props.commentUuid));
    toggle();
  };
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="notes-modal modal-btn-spaced">
      <ModalHeader toggle={toggle} className="border-0">
        Remove comment as accepted answer?
      </ModalHeader>
      <ModalFooter className="border-0 pt-0 pb-4 justify-content-between mt-5">
        <Button color="primary" outline onClick={toggle}>
          Cancel
        </Button>
        <Button color="danger" onClick={removeApprovedAnswer}>
          Remove
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export const CommentDetail = (props) => {
  const { comment, allAuthorMetadata, isMainComments = true } = props;

  const [reportsModal, setReportsModal] = useState(false);
  const toggleReportsModal = () => setReportsModal(!reportsModal);
  const commentUuid = get(comment, "commentUuid");
  const caseData = get(comment, "case");
  const createdAt = get(comment, "createdAt");
  const updatedAt = get(comment, "updatedAt");
  const reports = get(comment, "reports");
  const commentGroupUuid = get(comment, "case.groupUuid");
  const isAuthor = get(comment, "isCaseAuthor");
  const caseUuid = get(caseData, "caseUuid");
  const title = get(caseData, "title");
  const caption = get(caseData, "caption");
  const isAcceptedAnswer = get(comment, "isAcceptedAnswer");
  const moderatorReviewed = get(comment, "moderatorReviewed");
  const isApprovedComment = comment?.state === COMMENT_STATES.APPROVED;
  const caseType = get(
    comment,
    "case.caseClassification",
    CASE_CLASSIFICATION_TYPE.MEDICAL
  );
  const caseTypeLabel =
    caseType === CASE_CLASSIFICATION_TYPE.MEDICAL
      ? CASE_CLASSIFICATION_NAME.MEDICAL
      : CASE_CLASSIFICATION_NAME.NONMEDICAL;

  const caseTypeIcon =
    caseType === CASE_CLASSIFICATION_TYPE.MEDICAL ? CaseIcon : PostIcon;

  const caseDisplayTitle = truncate(title || caption, { length: 80 });
  const groupDetails = useSelector((state) => {
    return state.reference?.groups?.find(
      (group) => group.groupUuid === commentGroupUuid
    );
  });

  const renderTag = (comment) => {
    const validStates = [
      "approved",
      "flagged",
      "reported",
      "rejected",
      "deleted",
      "alerted",
      "pending_approval",
      "pending_approval_flagged"
    ];

    const state = get(comment, "state");
    const isValidState = includes(validStates, state);
    if (!isValidState) {
      return null;
    }

    return (
      <Badge className={`badge-pill ${state} mr-1`}>
        <FontAwesomeIcon icon={faExclamation} size={"sm"} />
        {renderStateName(state)}
      </Badge>
    );
  };

  const renderStateName = (stateName) => {
    if (
      isEqual(CAMPAIGN_COMMENTS_SUB_COLLECTIONS.FLAGGED.statsKey, stateName)
    ) {
      return CAMPAIGN_COMMENTS_SUB_COLLECTIONS.FLAGGED.displayName;
    }

    return startCase(stateName);
  };

  const showNormalActions =
    !isApprovedComment ||
    !isMainComments ||
    !isAcceptedAnswer ||
    (isAcceptedAnswer && moderatorReviewed);

  const columnOffset = showNormalActions ? 0 : 1;

  return (
    <>
      <Card key={`comment-${commentUuid}`} className="comment-card mb-3">
        <CardBody className="mr-0 pr-0 py-3 border-0">
          <div className="comments-flex-container d-flex">
            {/* adjust col size if isAcceptedAnswer */}
            <Col
              xl={8 - columnOffset}
              lg={7 - columnOffset}
              md={5}
              className="pl-2">
              {renderTag(comment)}
              {!isEqual(updatedAt, createdAt) && (
                <span className="font-italic text-secondary ml-2">
                  Updated at {moment(updatedAt).format("h:mm, MMMM Do, YYYY")}
                </span>
              )}
              <div className="font-weight-bold mt-2 text-14">
                {caseUuid && (
                  <a
                    className="font-weight-bold"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={getWebCaseUrl(caseUuid)}>
                    {caseDisplayTitle}
                  </a>
                )}
                {!caseUuid && caseDisplayTitle}
              </div>
              <div className="mt-2 comment-text-block">
                {isAuthor && (
                  <div
                    className="rounded d-inline-flex bg-dark-gray text-white mr-2 pl-1 pr-1 text-11"
                    style={{ paddingTop: "2px", paddingBottom: "2px" }}>
                    Author
                  </div>
                )}
                <AcceptedAnswerLabel comment={comment} />
                {get(comment, "text")}
                <CommentsGroupBanner groupDetails={groupDetails} />
                <CommentAnonBanner comment={comment} />
              </div>

              <div className="font-italic text-secondary text-13 mt-2">
                Commented at {moment(createdAt).format("h:mm, MMMM Do, YYYY")}
              </div>
              {!isEmpty(reports) && (
                <div className="pt-1">
                  <Button
                    className="px-0 text-15 font-weight-500"
                    color="link"
                    onClick={toggleReportsModal}>
                    See reports ({size(reports)})
                  </Button>
                </div>
              )}
            </Col>
            <Col
              xl={3}
              lg={4}
              md={5}
              className="case-author-detail pl-2 border-left">
              <CommentAuthorDetail
                comment={comment}
                allAuthorMetadata={allAuthorMetadata}
              />
              <div
                style={{ paddingRight: "10px" }}
                className="pl-2 py-1 rounded d-inline-flex bg-lighter-gray mt-2 text-14 f1-weight-medium align-items-center">
                <img
                  src={caseTypeIcon}
                  alt=""
                  className="mr-1"
                  style={{ height: "22px" }}
                />
                {caseTypeLabel}
              </div>
            </Col>
            {/* adjust col size if isAcceptedAnswer */}
            <Col
              lg={1 + columnOffset}
              md={2 + columnOffset}
              className="px-0 border-left">
              {showNormalActions && (
                <CommentActionButtons
                  activeComment={comment}
                  iconSize="small"
                />
              )}
              {!showNormalActions && (
                <ApprovedCommentActionButtons activeComment={comment} />
              )}
            </Col>
          </div>
        </CardBody>
      </Card>
      <CommentReportsModal
        isOpen={reportsModal}
        toggle={toggleReportsModal}
        reports={reports}
      />
    </>
  );
};

CommentDetail.propTypes = {
  comment: PropTypes.object,
  allAuthorMetadata: PropTypes.object,
  isMainComments: PropTypes.bool
};

export const CommentAuthorDetail = (props) => {
  const { comment, allAuthorMetadata } = props;

  const authorUuid = get(comment, "authorUuid");
  const username = get(comment, "authorUsername");
  const avatar = get(comment, "authorAvatar");
  const profession = get(comment, "authorProfessionName");

  const authorMetadata = get(allAuthorMetadata, authorUuid, {});
  const totalComments = get(authorMetadata, "all", 0);
  const totalFlagged = get(authorMetadata, "flagged", 0);
  const totalReported = get(authorMetadata, "reported", 0);
  const totalRejected = get(authorMetadata, "rejected", 0);

  return (
    <Card className="border-0 px-2">
      <div className="d-inline-flex p-0 mb-2">
        <ProfileImage avatar={avatar} username={username} />
        <CardBody className="d-flex flex-column py-0 px-2">
          <div className="mb-0 text-14">{username}</div>
          <div className="text-14 text-secondary">{profession}</div>
        </CardBody>
      </div>
      <CardBody className="m-0 p-0">
        <CardText className="my-0">
          <strong>{totalComments}</strong> Comments
        </CardText>
        <CardText className="my-0">
          <strong>{totalFlagged}</strong> Flagged
        </CardText>
        <CardText className="my-0">
          <strong>{totalReported}</strong> Reported
        </CardText>
        {totalRejected > 0 ? (
          <span className="my-0">
            <strong>{totalRejected}</strong> Rejected
          </span>
        ) : (
          <CardText className="my-0">
            <strong>0</strong> Rejected
          </CardText>
        )}
      </CardBody>
    </Card>
  );
};

CommentAuthorDetail.propTypes = {
  comment: PropTypes.object,
  allAuthorMetadata: PropTypes.object
};
