import React, { useRef, useState } from "react";
import {
  Alert,
  Button,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import {
  clone,
  defaultTo,
  find,
  get,
  head,
  isEmpty,
  isEqual,
  isNil,
  map,
  set
} from "lodash";
import PropTypes from "prop-types";
import "./modal.scss";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "../common/LoadingButton";
import Row from "reactstrap/es/Row";
import { editVerificationUser } from "../../actions/verification.actions";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { fetchSchools } from "../../utils/autocomplete-utils";
import { getFieldValue, onInvalidSubmit } from "../../utils/form-utils";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { findSpecialty, generateDropdown } from "../../utils/reference-utils";
import {
  getProfessionUuid,
  getSpecialtyUuid
} from "../../utils/verification-utils";

const EditInfoModal = (props) => {
  const { userData, isOpen, toggle, message, isError } = props;
  const [formData, setFormData] = useState({});
  const dispatch = useDispatch();
  const saving = useSelector((state) => state.verification.editSaving);
  const professions = useSelector((state) => state.reference.professions);
  const countries = useSelector((state) => state.reference.countries);
  const states = useSelector((state) => state.reference.states);

  const selectedSchool = useRef([]);
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);

  const onSubmit = async (event) => {
    event.preventDefault();

    const userUid = get(userData, "userUid");
    formData.primarySpecialty =
      formData._specialty?.uuid || formData._profession?.uuid;

    await dispatch(editVerificationUser(userUid, formData));
    toggle();
  };

  const onOpened = () => {
    const professionUuid = getProfessionUuid(userData, "");
    const specialtyUuid = getSpecialtyUuid(userData, "");
    const profession = find(professions, (p) => {
      return p.typeUuid === professionUuid;
    });
    const currentSpecialty = findSpecialty(
      profession,
      specialtyUuid,
      "specialties"
    );

    const schoolUuid = defaultTo(get(userData, "schoolUuid"), "");
    const schoolName = defaultTo(get(userData, "schoolName"), "");

    if (!isEmpty(schoolUuid) && !isEmpty(schoolName)) {
      selectedSchool.current[0] = { label: schoolName, uuid: schoolUuid };
    }

    setFormData({
      userUuid: get(userData, "userUuid"),
      firstName: defaultTo(get(userData, "firstName"), ""),
      lastName: defaultTo(get(userData, "lastName"), ""),
      schoolUuid: schoolUuid,
      graduationYear: defaultTo(get(userData, "graduationYear"), ""),
      npiNumber: defaultTo(get(userData, "npi.npiNumber"), ""),
      medicalLicense: defaultTo(get(userData, "license.licenseNumber"), ""),
      stateUuid: defaultTo(get(userData, "stateUuid"), ""),
      countryUuid: defaultTo(get(userData, "countryUuid"), ""),
      _profession: profession,
      _specialty: currentSpecialty
    });
  };

  const onSearch = async (query) => {
    setLoading(true);
    const response = await fetchSchools(query);
    const foundSuggestions = map(get(response, "hits.hits", []), (s) => {
      return { label: get(s, "_source.schoolName"), uuid: get(s, "_id") };
    });

    setSuggestions(foundSuggestions);
    setLoading(false);
  };

  const onChange = (event) => {
    const { name, value } = event.target;

    setFormData({ ...formData, [name]: value });
  };

  const onChangeSchool = (school) => {
    const value = head(school);
    if (value) {
      selectedSchool.current[0] = value;
      setFormData(clone(set(formData, "schoolUuid", value.uuid)));
    }
  };

  const onChangeProfession = (value) => {
    const newData = clone(formData);
    newData._profession = value;
    newData._specialty = null;

    setFormData(newData);
  };

  const onChangeSpecialty = (value) => {
    const newData = clone(formData);
    newData._specialty = value;
    setFormData(newData);
  };

  const onChangeCountry = (value) => {
    const newData = clone(formData);
    newData.countryUuid = value.uuid;
    newData.stateUuid = null;
    setFormData(newData);
  };

  const onChangeState = (value) => {
    setFormData(clone(set(formData, "stateUuid", value.uuid)));
  };

  const professionSegment = generateDropdown(
    "profession",
    "Profession",
    professions,
    "Select a profession",
    onChangeProfession,
    formData._profession
  );

  const specialtySegement =
    isNil(formData._profession?.specialties) ||
    isEmpty(formData._profession?.specialties)
      ? null
      : generateDropdown(
          "specialty",
          "Speciality",
          formData._profession?.specialties,
          "Select a specialty",
          onChangeSpecialty,
          formData._specialty,
          "uuid"
        );

  const countrySegment = generateDropdown(
    "countryUuid",
    "Country",
    countries,
    "Select a country",
    onChangeCountry,
    find(countries, (c) => isEqual(c.uuid, formData.countryUuid)),
    "uuid"
  );

  const countryStates = get(states, formData.countryUuid, []);
  const statesSegment = isEmpty(countryStates)
    ? null
    : generateDropdown(
        "stateUuid",
        "State",
        countryStates,
        "Select a state",
        onChangeState,
        find(countryStates, (s) => isEqual(s.uuid, formData.stateUuid)),
        "uuid"
      );

  return (
    <Modal
      isOpen={isOpen}
      onOpened={onOpened}
      toggle={toggle}
      className="caption-edit-modal modal-btn-spaced">
      <ModalHeader toggle={toggle} className="border-0">
        Edit the fields below
      </ModalHeader>
      <AvForm onValidSubmit={onSubmit} onInvalidSubmit={onInvalidSubmit}>
        <ModalBody className="pt-0">
          {message && (
            <Alert color={isError ? "danger" : "success"}>{message}</Alert>
          )}
          <Row>
            <AvField
              id="firstName"
              name="firstName"
              label="firstName"
              type="text"
              groupAttrs={{ className: "col" }}
              labelClass="text-11 text-uppercase font-weight-normal"
              value={getFieldValue(formData, "firstName")}
              onChange={onChange}
              errorMessage="Required"
              required
            />

            <AvField
              id="lastName"
              name="lastName"
              label="lastName"
              type="text"
              groupAttrs={{ className: "col" }}
              labelClass="text-11 text-uppercase font-weight-normal"
              value={getFieldValue(formData, "lastName")}
              onChange={onChange}
              errorMessage="Required"
              required
            />
          </Row>

          {professionSegment}
          {specialtySegement}

          {countrySegment}
          {statesSegment}

          <Row>
            <AvField
              id="npiNumber"
              name="npiNumber"
              label="npiNumber"
              type="text"
              groupAttrs={{ className: "col" }}
              labelClass="text-11 text-uppercase font-weight-normal"
              value={getFieldValue(formData, "npiNumber")}
              onChange={onChange}
            />

            <AvField
              id="medicalLicense"
              name="medicalLicense"
              label="medicalLicense"
              type="text"
              groupAttrs={{ className: "col" }}
              labelClass="text-11 text-uppercase font-weight-normal"
              value={getFieldValue(formData, "medicalLicense")}
              onChange={onChange}
            />
          </Row>

          <Row>
            <FormGroup className="col">
              <Label className="text-11 text-uppercase font-weight-normal">
                school
              </Label>
              <AsyncTypeahead
                id="schoolsTypeahead"
                minLength={3}
                isLoading={loading}
                onSearch={onSearch}
                options={suggestions}
                placeholder="Select a school..."
                onChange={onChangeSchool}
                onFocus={() => (selectedSchool.current.length = 0)}
                selected={selectedSchool.current}
              />
            </FormGroup>

            <AvField
              id="graduationYear"
              name="graduationYear"
              label="graduationYear"
              type="text"
              groupAttrs={{ className: "col" }}
              labelClass="text-11 text-uppercase font-weight-normal"
              value={getFieldValue(formData, "graduationYear")}
              onChange={onChange}
            />
          </Row>
        </ModalBody>
        <ModalFooter className="border-0 pt-0 pb-4 justify-content-between">
          <Button color="primary" outline onClick={toggle}>
            Cancel
          </Button>

          <LoadingButton
            outline
            color="primary"
            type="submit"
            loading={saving}
            disabled={saving}>
            Update
          </LoadingButton>
        </ModalFooter>
      </AvForm>
    </Modal>
  );
};

EditInfoModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  caseDoc: PropTypes.object
};

EditInfoModal.defaultProps = {};

export default EditInfoModal;
