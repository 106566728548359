import React, { useEffect, useState } from "react";
import "./style.scss";
import {
  Button,
  Col,
  Container,
  Input,
  Row,
  TabContent,
  TabPane
} from "reactstrap";
import {
  compact,
  concat,
  find,
  get,
  includes,
  isEmpty,
  isEqual,
  join,
  map,
  size
} from "lodash";
import { VERIFICATION_ROOT } from "../../constants/routes";
import { VERIFICATION_SUB_COLLECTIONS } from "../../db/db-constants";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import VerificationTabs from "../../components/navigation/VerificationTabs";
import VerificationSubNavigation from "./components/VerificationSubNavigation";
import {
  getVerificationUsers,
  setActiveVerificationUser,
  setBulkUser,
  subscribePriorityFiltersListener
} from "../../actions/verification.actions";
import {
  getDisplayName,
  getProfessionName,
  getSpecialtyName,
  getUserNumber,
  getVerificationTagColor,
  VERIFICATION_TABS
} from "../../utils/verification-utils";
import VerificationLeftNavigation from "./components/VerificationLeftNavigation";
import {
  VerificationDetails,
  VerificationFilters,
  VerificationHistory,
  VerificationNotes,
  VerificationPriorityUserIcon,
  VerificationUserHeading,
  VerificationUserTags
} from "./components/VerificationSections";
import VerificationAllNav from "../../components/navigation/VerificationAllNav";
import {
  VerificationPagination,
  VerificationRecordPagination
} from "./components/VerificationNavigation";
import {
  getAllCountriesAndStates,
  getAllGroups,
  getAllProfessionAndSpecialties
} from "../../actions/reference.actions";
import { faTags, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EmptyContainer from "../../components/common/EmptyContainer";
import Loading from "../../components/Loading";
import {
  convertCountry,
  useGetProfessionStackByTreeUuid
} from "../../utils/reference-utils";

const VerificationLandingPage = () => {
  const {
    verificationType = "npi",
    verificationStatus = VERIFICATION_SUB_COLLECTIONS.PENDING.statsKey
  } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const users = useSelector((state) => state.verification.users);
  const processing = useSelector(
    (state) =>
      state.verification.processing || !state.reference.professions?.length
  );
  const typeStats = useSelector((state) => state.verification.typeStats);
  const statusStats = useSelector((state) => state.verification.statusStats);
  const activeUser = useSelector((state) => state.verification.activeUser);
  const bulkUsers = useSelector((state) => state.verification.bulkUsers);
  const countries = useSelector((state) => state.reference.countries);
  const bulkEditActive = size(bulkUsers) > 1;

  useEffect(() => {
    dispatch(subscribePriorityFiltersListener());
    dispatch(getAllProfessionAndSpecialties());
    dispatch(getAllCountriesAndStates());
    dispatch(getAllGroups());
  }, [dispatch]);

  const filter =
    find(
      VERIFICATION_SUB_COLLECTIONS,
      (f) => f.statsKey === verificationStatus
    ) || VERIFICATION_SUB_COLLECTIONS.PENDING;

  const activeUserData = activeUser;
  const activeUserUuid = get(activeUserData, "userUuid");
  const [activeTab, setActiveTab] = useState(VERIFICATION_TABS.INFO.tabName);

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const baseUrl = `${VERIFICATION_ROOT}/${verificationType}`;

  useEffect(() => {
    dispatch(getVerificationUsers(verificationType, verificationStatus, 0));
  }, [location, dispatch, verificationType, verificationStatus]);

  const setActiveUser = (user) => {
    dispatch(setActiveVerificationUser(user));
  };

  const onSetBulkUser = (user, userUid, active) => {
    active && setActiveUser(user);
    dispatch(setBulkUser(userUid, active));
  };

  const renderUserDetailBody = () => {
    return (
      <Col fluid="lg1" lg={8} className="mt-4">
        <VerificationUserHeading userData={activeUserData} />

        <div className="col-border-bottom my-3"></div>

        <VerificationUserTags userData={activeUserData} />

        <VerificationSubNavigation
          active={activeTab}
          toggle={toggleTab}
          collection={VERIFICATION_TABS}
          userData={activeUserData}
        />

        <TabContent activeTab={activeTab}>
          <TabPane tabId={VERIFICATION_TABS.INFO.tabName} className="mt-4">
            <VerificationDetails userData={activeUserData} />
          </TabPane>

          <TabPane tabId={VERIFICATION_TABS.NOTES.tabName} className="mx-2">
            <VerificationNotes userData={activeUserData} />
          </TabPane>

          <TabPane tabId={VERIFICATION_TABS.HISTORY.tabName}>
            <VerificationHistory userData={activeUserData} />
          </TabPane>
        </TabContent>

        <VerificationRecordPagination />
      </Col>
    );
  };

  const ListItem = ({ userData }) => {
    const userUid = get(userData, "userUid");
    const userUuid = get(userData, "userUuid");
    const { profession, subspecialty } = useGetProfessionStackByTreeUuid(
      userData?.professionChangeRequest?.professionTreeUuid
    );

    const specialties = compact(
      concat(getProfessionName(userData), getSpecialtyName(userData))
    );

    let displayTitle = join(specialties, ", ");
    if (userData?.professionChangeRequest?.professionTreeUuid) {
      displayTitle = join(
        compact([profession?.name, subspecialty ? subspecialty.name : null]),
        ", "
      );
    }

    const npiCountry = get(userData, "npi.npiCountry");
    const profileCountry = convertCountry(
      get(userData, "countryUuid"),
      countries
    );
    const country = npiCountry
      ? npiCountry
      : profileCountry
      ? profileCountry.name
      : "";
    const userTags = get(userData, "verificationTags", []) || [];
    return (
      <li
        className={`list-group-item ${
          isEqual(activeUserUuid, userUuid) ? "user-active" : ""
        } ${includes(bulkUsers, userUid) ? "bulk-active" : ""}`}>
        <div className="d-flex">
          <div className="d-flex flex-column align-items-center mr-2">
            <Input
              className="ml-0 mb-2"
              style={{ position: "relative" }}
              type="checkbox"
              checked={includes(bulkUsers, userUid)}
              onChange={(e) =>
                onSetBulkUser(userData, userUid, e.target.checked)
              }
            />

            <VerificationPriorityUserIcon userData={userData} />
          </div>
          <div
            className="flex-grow-1 cursor-pointer"
            onClick={() => setActiveUser(userData)}>
            {!bulkEditActive && (
              <div className="d-flex flex-column">
                <div className="d-flex justify-content-between text-14">
                  <strong>{getDisplayName(userData)}</strong>
                  <div>
                    {!!userTags.length && (
                      <FontAwesomeIcon
                        icon={faTags}
                        className="icon-color-teal mr-1"
                      />
                    )}
                    <span>{getUserNumber(userData, verificationType)}</span>
                  </div>
                </div>
                <div className="d-flex justify-content-between pt-1">
                  <div className="text-secondary text-12">{displayTitle}</div>
                  <div className="text-secondary text-12">{country}</div>
                </div>
              </div>
            )}

            {bulkEditActive && (
              <Row className={"ml-0"}>
                <Col lg={"3"} className={"pl-0 border-right"}>
                  <strong>{getDisplayName(userData)}</strong>
                  <div className="text-secondary text-12">
                    {join(specialties, ", ")}
                  </div>
                </Col>
                <Col>
                  {map(userTags, (t) => {
                    return (
                      <Button
                        key={`user-display-tag-${t.tagUuid}`}
                        color={getVerificationTagColor(t.tagName)}
                        size="sm"
                        className={"mr-2 mb-1"}>
                        {t.tagName}
                        <FontAwesomeIcon
                          icon={faTimes}
                          className="text-white ml-2"
                        />
                      </Button>
                    );
                  })}
                </Col>
                <Col lg={"3"} className={"text-right border-left"}>
                  <strong>{getUserNumber(userData, verificationType)}</strong>
                  <div className="text-secondary text-12">{country}</div>
                </Col>
              </Row>
            )}
          </div>
        </div>
      </li>
    );
  };

  const renderInnerBody = () => {
    if (isEmpty(users)) {
      return (
        <EmptyContainer
          title={`No Users Found Under "${filter.displayName}"`}
          description="All the users have have been processed associated with this filter."
        />
      );
    }

    return (
      <Row>
        <Col lg={bulkEditActive ? "12" : "4"} className="col-border-right px-0">
          <VerificationPagination bulkEditActive={bulkEditActive} />

          <ul className="list-group verification-author verification mx-0 px-0">
            {map(users, (u, index) => (
              <ListItem userData={u} key={`user-${index}`} />
            ))}
          </ul>
        </Col>

        {!bulkEditActive && renderUserDetailBody()}
      </Row>
    );
  };

  const renderBody = () => {
    if (processing) {
      return <Loading />;
    }

    return (
      <Row className="mx-0 min-vh-100">
        <Col className="col-border-right py-4 px-0 min-vh-100" xl="2">
          <VerificationLeftNavigation
            active={verificationStatus}
            baseUrl={baseUrl}
            stats={statusStats}
            collection={VERIFICATION_SUB_COLLECTIONS}
          />
        </Col>
        <Col lx="10" className="my-0">
          {renderInnerBody()}
        </Col>
      </Row>
    );
  };

  return (
    <Container className="mt-4 pb-4">
      <Row className="m-auto" style={{ position: "relative" }}>
        {!bulkEditActive && (
          <VerificationTabs active={verificationType} stats={typeStats} />
        )}
        <VerificationFilters />
        <VerificationAllNav isOpen={bulkEditActive} />
      </Row>

      <TabContent className="bg-white" activeTab={"1"}>
        <TabPane tabId="1" className="min-vh-100">
          {renderBody()}
        </TabPane>
      </TabContent>
    </Container>
  );
};

export default VerificationLandingPage;
