import React from "react";
import { Alert } from "reactstrap";

const CommentsGroupBanner = ({ groupDetails }) => {
  if (!groupDetails) {
    return null;
  }

  return (
    <Alert className="mt-2 mb-0 bg-other-green text-darkest-gray font-weight-normal px-3 py-2">
      This comment was posted in {groupDetails.groupName} group
    </Alert>
  );
};

export default CommentsGroupBanner;
